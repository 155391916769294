import React from "react";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { required } from "@gbli-events/common/src/Helpers/validators";
import SelectList from "@gbli-events/common/src/Components/FormElements/SelectList";
import CurrencyHelper from "src/Helpers/CurrencyHelper";
import initialFormValues from "src/Constants/initialFormValues";
import { getTheme } from "src/Selectors/Shared";

const propTypes = {
  drpValues: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const DamageToRentedPremisesField = ({ drpValues }) => {
  const options = drpValues.map((value) => ({
    label: new CurrencyHelper(value).dollars(),
    value,
  }));

  const theme = useSelector(getTheme);

  return (
    <Field
      name="ACDamageToRentedProperty"
      component={SelectList}
      theme={theme}
      options={options}
      placeholder="Select Coverage"
      validate={required}
      resetValue={initialFormValues().ACDamageToRentedProperty}
      components={{
        ClearIndicator: () => null,
      }}
    />
  );
};

DamageToRentedPremisesField.propTypes = propTypes;

export default DamageToRentedPremisesField;
