import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "redux-form";
import {
  GBLI_EMAIL_EVENTS,
  GBLI_PHONE_EVENTS_TITLE,
} from "@gbli-events/common/src/Constants/shared";
import FormStepNavigation from "src/Components/FormStepNavigation";
import AsyncButton from "@gbli-events/common/src/Components/AsyncButton";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import CCFieldGroup from "@gbli-events/common/src/Components/FormElements/CCFieldGroup/CCFieldGroup";
import RadioFieldGroup from "@gbli-events/common/src/Components/FormElements/RadioFieldGroup/RadioFieldGroup";
import AddressFieldGroup from "@gbli-events/common/src/Components/FormElements/AddressFieldGroup";
import StyledCheckboxField from "@gbli-events/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import {
  creditCardDate,
  required,
} from "@gbli-events/common/src/Helpers/validators";
import {
  FORM_CONTROL_YES,
  FORM_CONTROL_NO,
} from "@gbli-events/common/src/Constants/formControls";
import {
  ENTITY_COMPANY,
  ENTITY_PERSON,
} from "@gbli-events/common/src/Constants/entityTypes";
import ReactRouterPropTypes from "react-router-prop-types";
import { THANK_YOU_URL } from "src/Constants/constants";
import { formName } from "src/Constants/constants";
import { getFormValues } from "redux-form";
import { createStateOptions } from "../../Helpers/StateSelectOptions";
import { getUsStates, getTheme } from "src/Selectors/Shared";
import { getIsPaymentPageValid, getCCParams } from "src/Selectors/Payment";
import PaymentService from "src/Helpers/PaymentService";
import CyberSourceService from "src/Helpers/CyberSourceService";
import API from "src/Helpers/API";
import getPaymentBody from "src/Helpers/ExtractFormState";
import {
  completedOrder,
  errorResponse,
  setInsuredName,
  trackConversion,
} from "src/Actions/actions";
import { getDashedInsuredName } from "src/Selectors/InsuredInformation";
import Alert from "@gbli-events/common/src/Components/Themed/Alert";
import { getTotalAmount } from "src/Selectors/Quote";
import CurrencyHelper from "@gbli-events/common/src/Helpers/CurrencyHelper";
import { getMainVenue } from "src/Selectors/Venue";
import { getInsuranceCompanyByState } from "src/Selectors/InsuranceCompany";
import { useInsuranceCompanies } from "src/Hooks/InsuranceCompanies";

const ccDateValidator = creditCardDate("payeeCardExpMon", "payeeCardExpYr");

const propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

const Payment = ({ history }) => {
  useInsuranceCompanies();

  const { payeeSame, payeeRenter } = useSelector(getFormValues(formName));
  const usStatesOptions = createStateOptions(useSelector(getUsStates));
  const isPaymentPageValid = useSelector(getIsPaymentPageValid);
  const basePayload = useSelector(getPaymentBody);
  const dashedInsuredName = useSelector(getDashedInsuredName);
  const theme = useSelector(getTheme);
  const totalAmount = useSelector(getTotalAmount);
  const mainVenue = useSelector(getMainVenue);
  const insuranceCompany = useSelector(
    getInsuranceCompanyByState(mainVenue.selectedPlaceAddressComponents.state)
  );
  const venueState = basePayload.venueAddress[0]?.state;
  const dispatch = useDispatch();

  const ccParams = useSelector(getCCParams);

  const [isSaving, setIsSaving] = useState(false);
  const [apiError, setApiError] = useState(false);

  const submit = () => {
    setIsSaving(true);
    const paymentSrvc = new PaymentService(new API());
    paymentSrvc
      .getFlexKey()
      .then(
        ({ data: { keyId } }) => {
          return new CyberSourceService(
            new API(`${process.env.REACT_APP_CYBERSOURCE_FLEX_API}`)
          ).tokenizeCard(ccParams, keyId);
        },
        (err) => {
          dispatch(errorResponse(err));
        }
      )
      .then(({ data: { token, maskedPan, cardType } }) => {
        const cardInfo = [
          {
            ...basePayload.cardInfo[0],
            token,
            lastFour: maskedPan.substring(maskedPan.length - 4),
            cardType,
          },
        ];

        return paymentSrvc.createCoverage({ ...basePayload, cardInfo });
      })
      .then(({ data }) => {
        setIsSaving(false);
        setApiError(false);
        dispatch(setInsuredName(dashedInsuredName));
        dispatch(trackConversion(data));
        history.push(THANK_YOU_URL);
        dispatch(completedOrder(data));
      })
      .catch((err) => {
        setIsSaving(false);
        setApiError(true);
        dispatch(errorResponse(err));
      });
  };

  return (
    <div className="payment">
      <div className="container">
        <FormStepNavigation />
        <div className="page-heading">
          <h2>Payment Information</h2>
        </div>
        <CCFieldGroup
          cardNumberControl="payeeCardNum"
          cardNameControl="payeeCardName"
          cardExpMonControl="payeeCardExpMon"
          cardExpYrControl="payeeCardExpYr"
          cardCVVControl="payeeCardCVV"
          cardDateValidator={ccDateValidator}
          theme={theme}
        />
        <RadioFieldGroup
          controlName="payeeSame"
          label="Billing address same as the contact"
          options={[
            {
              label: "Yes",
              value: FORM_CONTROL_YES,
            },
            { label: "No", value: FORM_CONTROL_NO },
          ]}
        />
        {payeeSame === FORM_CONTROL_NO && (
          <>
            <RadioFieldGroup
              controlName="payeeRenter"
              validators={[required]}
              options={[
                {
                  label: "Individual",
                  value: ENTITY_PERSON,
                },
                {
                  label: "Company/Organization",
                  value: ENTITY_COMPANY,
                },
              ]}
            />
            {payeeRenter === ENTITY_PERSON && (
              <>
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-sm">
                      <Field
                        component={TextField}
                        label="First Name"
                        validate={[required]}
                        name="payeeFirstName"
                        required
                        type="text"
                      />
                    </div>
                    <div className="col-sm">
                      <Field
                        component={TextField}
                        label="Last Name"
                        validate={[required]}
                        required
                        name="payeeLastName"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {payeeRenter === ENTITY_COMPANY && (
              <>
                <div className="form-group">
                  <Field
                    component={TextField}
                    label="Company/Organization Name"
                    placeholder="Company/Organization Name"
                    validate={[required]}
                    required
                    name="payeeCompany"
                  />
                </div>
              </>
            )}
            <AddressFieldGroup
              usStatesOptions={usStatesOptions}
              streetAddressControl="payeeAddress"
              addressLine2Control="payeeAddress2"
              cityControl="payeeCity"
              stateControl="payeeState"
              zipControl="payeeZip"
              theme={theme}
            />
          </>
        )}
        {apiError && (
          <Alert variant="error" className="mt-3">
            <i className="far fa-exclamation-circle alert__icon" />
            <div className="alert__text">
              Sorry, we were unable to create the coverage. Please try again or
              contact us at{" "}
              <span className="text-nowrap">{GBLI_PHONE_EVENTS_TITLE}</span> or{" "}
              {GBLI_EMAIL_EVENTS} for further assistance.{" "}
            </div>
          </Alert>
        )}
        <div className="label mb-3">
          Total Charge:{" "}
          {new CurrencyHelper(totalAmount).convertFromInt().formatDollars()}
        </div>
        {insuranceCompany && (
          <Field
            name="consentConfirmed"
            component={StyledCheckboxField}
            label={`I declare under penalty of perjury that the foregoing information provided in the application is true and correct, and that any misstatement of fact in the information given, which if known to J.H. Ferguson and Associates would have caused ${insuranceCompany.company} to decline this application, is grounds for voiding the policy. I verify that if I discover or become aware of any significant change in the conditions stated in this Application between the date of this Application and the policy inception date, which would render the Application inaccurate or incomplete, notice of such change will be reported in writing to J.H Ferguson and Associates immediately and any outstanding policy may be modified or withdrawn.`}
          />
        )}
        {venueState === "ND" && (
          <Field
            className="mt-3"
            name="northDakotaDisclosure"
            component={StyledCheckboxField}
            label={
              <>
                <p>
                  <b>North Dakota Disclosure</b>
                </p>
                <p>Notice:</p>
                <ol>
                  <li>
                    An insurer that is not licensed in this state is issuing the
                    insurance policy that you have applied to purchase. These
                    companies are called “nonadmitted” or “surplus lines”
                    insurers.
                  </li>
                  <li>
                    The insurer is not subject to the financial solvency
                    regulation and enforcement that applies to licensed insurers
                    in this state.
                  </li>
                  <li>
                    These insurers generally do not participate in insurance
                    guaranty funds created by state law. These guaranty funds
                    will not pay your claims or protect your assets if the
                    insurer becomes insolvent and is unable to make payments as
                    promised.
                  </li>
                  <li>
                    Some states maintain lists of approved or eligible surplus
                    lines insurers and surplus lines producers may use only
                    insurers on the lists. Some states issue orders that
                    particular surplus lines insurers cannot be used.
                  </li>
                  <li>
                    For additional information about the above matters and about
                    the insurer, you should ask questions of your insurance
                    producer or surplus lines producer. You may also contact
                    your insurance department consumer help line.
                  </li>
                </ol>
              </>
            }
          />
        )}
        <AsyncButton
          className="continue-btn"
          spinning={isSaving}
          spinningText="Processing"
          disabled={!isPaymentPageValid}
          onClick={submit}
        >
          Purchase Now
        </AsyncButton>
      </div>
    </div>
  );
};

Payment.propTypes = propTypes;

export default Payment;
