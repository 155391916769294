import { connect } from "react-redux";
import {
  setDownloaded,
  incrementCounter as incrementCounterAction,
  setShowTimeoutMessage as setShowTimeoutMessageAction,
} from "../Actions/actions";
import ThankYou from "../Components/ThankYou/ThankYou";
import { getThemeSlug, getIsEmbed } from "src/Selectors/Shared";

const mapStateToProps = (state) => ({
  orderNumber: state.thankyouPage.completedOrder.orderNumber,
  policyNumber: state.thankyouPage.completedOrder.policyNumber,
  email: state.thankyouPage.completedOrder.email,
  pdfLink: state.thankyouPage.completedOrder.pdfLink,
  downloaded: state.thankyouPage.completedOrder.downloaded,
  insuredName: state.thankyouPage.completedOrder.insuredName,
  timeoutCounter: state.thankyouPage.completedOrder.timeoutCounter,
  showTimeoutMessage: state.thankyouPage.completedOrder.showTimeoutMessage,
  isEmbed: getIsEmbed(state),
  themeSlug: getThemeSlug(state),
});

const mapDispatchToProps = (dispatch) => ({
  incrementCounter: (val) => dispatch(incrementCounterAction(val)),
  setShowTimeoutMessage: () => dispatch(setShowTimeoutMessageAction(true)),
  setDownloaded: (val) => dispatch(setDownloaded(val)),
});

const ThankYouContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYou);

export default ThankYouContainer;
