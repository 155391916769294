const contactTypePerson = "Person";
const contactTypeIndividual = "Individual";
const contactTypeCorporation = "Corporation";
const contactTypeJointVentureCorporation = "Joint Venture Corporation";
const contactTypeJointVentureLLC = "Joint Venture LLC";
const contactTypePartnership = "Partnership";
const contactTypeLLC = "LLC";
const contactTypeOther = "Other";

const individualTypes = [contactTypePerson];
const companyTypes = [
  contactTypeIndividual,
  contactTypeCorporation,
  contactTypeJointVentureCorporation,
  contactTypeJointVentureLLC,
  contactTypePartnership,
  contactTypeLLC,
  contactTypeOther,
];

const allContactTypes = [...individualTypes, ...companyTypes];

export {
  contactTypePerson,
  contactTypeIndividual,
  contactTypeCorporation,
  contactTypeJointVentureCorporation,
  contactTypeJointVentureLLC,
  contactTypePartnership,
  contactTypeLLC,
  contactTypeOther,
  individualTypes,
  companyTypes,
  allContactTypes,
};
