import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, useLocation, useHistory } from "react-router-dom";
import { ConnectedRouter } from "@jauntin/react-ui";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import { ThemeProvider } from "styled-components";
import "./App.scss";
import FormContainer from "./Containers/FormContainer";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import GBLIHeader from "./Components/GBLIHeader/Header";
import GBLIFooter from "./Components/GBLIFooter";
import ScrollToTop from "./Helpers/ScrollToTop";
import BrowserFeatures from "./Helpers/BrowserFeatures";
import CustomSwitch from "@gbli-events/common/src/Components/CustomSwitch";
import {
  FORM_URL,
  FORM_EMBED_URL,
  SAMPLE_POLICY_URL,
} from "src/Constants/constants";
import {
  getIsAppDataLoaded,
  getHasAppDataError,
  getIsEmbed,
} from "./Selectors/Shared";
import { fetchTheme } from "@gbli-events/common/src/Actions/themes";
import { getTheme } from "./Selectors/Shared";
import GlobalStyles from "./GlobalStyles";
import {
  getThemeSlug,
  getFormParentPath,
  getFormChildPathDefinition,
} from "./Helpers/URLParser";
import Embed from "./Components/Embed";
import AppErrorMessage from "@gbli-events/common/src/Components/AppErrorMessage/AppErrorMessage";
import MaintenanceModeMessage from "@gbli-events/common/src/Components/AppErrorMessage/MaintenanceModeMessage";
import ErrorBoundary from "@gbli-events/common/src/Components/ErrorBoundary";
import { getIsInMaintenanceMode } from "./Selectors/Form";
import SamplePolicyDocumentContainer from "./Containers/SamplePolicyDocumentContainer";

const touch = () => {
  const touchClass = BrowserFeatures.isTouch() ? "touch" : "no-touch";
  return document.body.classList.add(touchClass);
};
touch();

// Google Tag Manager
if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  });
}

// Google Analytics (may be redundant once GA is handled through GTM above)
if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const App = () => {
  const isAppDataLoaded = useSelector(getIsAppDataLoaded);
  const hasAppDataError = useSelector(getHasAppDataError);
  const isInMaintenanceMode = useSelector(getIsInMaintenanceMode);
  const isEmbed = useSelector(getIsEmbed);
  const themeSlug = useSelector(getThemeSlug);
  const theme = useSelector(getTheme);
  const isGbliTheme = theme?.themeSlug === "gbli-events";

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const pushUrl = useSelector((state) => state.router.pushUrl);

  useEffect(() => {
    dispatch(fetchTheme(getThemeSlug()));
  }, [dispatch]);

  return (
    <>
      {isInMaintenanceMode ? (
        <MaintenanceModeMessage />
      ) : (
        <>
          {hasAppDataError ? (
            <AppErrorMessage />
          ) : (
            <ConnectedRouter.Router
              history={history}
              location={location}
              pushUrl={pushUrl}
              dispatch={dispatch}
            >
              {isAppDataLoaded ? (
                <ThemeProvider theme={theme}>
                  <GlobalStyles />
                  <div
                    id="page-wrapper"
                    className={`page-version-${process.env.REACT_APP_UI_VERSION} page-index`}
                  >
                    <CustomSwitch>
                      <Route
                        path={getFormChildPathDefinition(
                          SAMPLE_POLICY_URL,
                          isEmbed,
                          themeSlug
                        )}
                        component={SamplePolicyDocumentContainer}
                      />
                      <Fragment>
                        <ScrollToTop>
                          <CustomSwitch>
                            <Route path={getFormParentPath(theme.themeSlug)}>
                              <CustomSwitch>
                                <Route
                                  path={FORM_EMBED_URL}
                                  render={() => <Embed />}
                                />
                                <Route
                                  path={FORM_URL}
                                  render={() => (
                                    <>
                                      {isGbliTheme ? (
                                        <GBLIHeader />
                                      ) : (
                                        <Header />
                                      )}
                                      <ErrorBoundary>
                                        <div
                                          className={`main-content ${
                                            !isGbliTheme && "mb-5"
                                          }`}
                                        >
                                          <FormContainer />
                                        </div>
                                      </ErrorBoundary>
                                      {isGbliTheme ? (
                                        <GBLIFooter />
                                      ) : (
                                        <Footer />
                                      )}
                                    </>
                                  )}
                                />
                              </CustomSwitch>
                            </Route>
                          </CustomSwitch>
                        </ScrollToTop>
                      </Fragment>
                    </CustomSwitch>
                  </div>
                </ThemeProvider>
              ) : (
                <></>
              )}
            </ConnectedRouter.Router>
          )}
        </>
      )}
    </>
  );
};

export default App;
