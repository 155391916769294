import React, { useCallback } from "react";
import { isMobile } from "react-device-detect";
import FormStepNavigation from "src/Components/FormStepNavigation";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import StyledIntegerNumberInput from "@gbli-events/common/src/Components/FormElements/IntegerNumberInput/StyledIntegerNumberInput";
import {
  required,
  requiredDates,
  minAttendees,
  maxAttendees,
  minLength,
  maxLength,
} from "@gbli-events/common/src/Helpers/validators";
import StyledDateRangePickerField from "@gbli-events/common/src/Components/Event/DateRangePickerField/StyledDateRangePickerField";
import CheckboxDates from "@gbli-events/common/src/Components/Event/CheckboxDates";
import {
  isWeddingTypeEventSelected,
  isEventInformationPageValid,
  getSetupDates,
  getTeardownDates,
} from "src/Selectors/Event";
import NavContinueButton from "../NavContinueButton";
import { MAX_EVENT_NAME_LENGTH } from "@gbli-events/common/src/Constants/event";
import WeddingQuestions from "./WeddingQuestions";
import { formName } from "src/Constants/constants";
import { disabledDatesForDateRangePicker } from "src/Helpers/DateHelpers";

const minLength2 = minLength(2);
const maxEventNameLength = maxLength(MAX_EVENT_NAME_LENGTH);

const mapStateToProps = (state) => ({
  venueUtcOffset: state.formPage.venue.selectedPlaceUtcOffset || 0,
  isWeddingEventType: isWeddingTypeEventSelected(state),
  isEventInformationPageValid: isEventInformationPageValid(state),
});

const propTypes = {
  venueUtcOffset: PropTypes.number.isRequired,
  isWeddingEventType: PropTypes.bool.isRequired,
  isEventInformationPageValid: PropTypes.bool.isRequired,
};

const EventInformation = ({
  venueUtcOffset,
  isWeddingEventType,
  isEventInformationPageValid,
}) => {
  const eventSetupDates = useSelector(getSetupDates);
  const eventTeardownDates = useSelector(getTeardownDates);
  const { startDate } = useSelector((state) =>
    formValueSelector(formName)(state, "eventDateRange")
  );

  const disabledDates = useCallback(
    (day) =>
      disabledDatesForDateRangePicker({
        startDate: new Date(startDate),
        venueUtcOffset,
      })(day),
    [startDate, venueUtcOffset]
  );

  return (
    <div className="event-information container mb-4">
      <FormStepNavigation />
      <div className="page-heading">
        <h2>Please tell us about your event</h2>
      </div>
      <div className="event-information__form-container">
        <Field
          name="eventName"
          component={TextField}
          label="Name of your event:"
          ariaLabel="Name of your event"
          validate={[required, minLength2, maxEventNameLength]}
          required
          maxLength={MAX_EVENT_NAME_LENGTH}
          className="mb-md-5"
          lengthClassName="pt-1 c-gray-600 label-small"
        />
        <Field
          name="eventDailyGuests"
          className="event-information__guests mb-md-5"
          component={StyledIntegerNumberInput}
          label="How many people, on average, will attend each day?"
          placeholder=""
          min={1}
          max={500}
          validate={[required, minAttendees, maxAttendees]}
          required
        />
        <div className="event-information__dates mb-3 mb-md-5">
          <Field
            component={StyledDateRangePickerField}
            validate={requiredDates}
            name="eventDateRange"
            type="text"
            numberOfMonths={isMobile ? 1 : 2}
            inputClassName="form-control-lg"
            disabledDates={disabledDates}
            startDateLabel="Start / first day"
            endDateLabel="End / last day"
            label="What day(s) is your event, excluding any setup and teardown
                days? Choose up to 3 consecutive days."
          />
        </div>
        {isWeddingEventType && (
          <WeddingQuestions className="event-information" />
        )}
        <CheckboxDates
          label="Do you need coverage for set up on the below day?"
          className="mb-3 mb-md-5"
          controlName="eventSetupDates"
          controls={eventSetupDates}
        />
        <CheckboxDates
          label="Do you need coverage for teardown on the below day?"
          className="mb-3 mb-md-5"
          controlName="eventTeardownDates"
          controls={eventTeardownDates}
        />
      </div>

      <NavContinueButton disabled={!isEventInformationPageValid} />
    </div>
  );
};

EventInformation.propTypes = propTypes;

export default connect(mapStateToProps)(EventInformation);
