import styled from "styled-components";
import { getMediaBreakpointDown } from "@gbli-events/common/src/Helpers/themes";

const StyledFooter = styled.footer`
  .themed-footer__navigation {
    ${({ theme }) => getMediaBreakpointDown(theme.breakpoints.md)} {
      flex-direction: column;
    }
  }

  .themed-footer__navigation-item {
    flex-grow: 1;
    margin-bottom: 12px;
    ${({ theme }) => getMediaBreakpointDown(theme.breakpoints.md)} {
      margin-bottom: 25px;
    }
  }

  padding: 75px 0;

  .themed-footer__copyright {
    font-size: 12px;
  }

  .themed-footer__legal {
    font-size: 12px;
    margin-top: 25px;
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.components.Footer?.customCss}
`;

export default StyledFooter;
