import StyledFooter from "./StyledFooter";
import FooterLink from "./FooterLink/FooterLink";
import { useSelector } from "react-redux";
import { Nav } from "react-bootstrap";
import { format } from "date-fns";
import {
  getTheme,
  getValidatingReferralCodes,
  getReferralQueryParams,
  getStatesByInsuranceCompany,
} from "src/Selectors/Shared";
import { getAvailableStateCodes } from "../../Selectors/Venue";
import { useInsuranceCompanies } from "src/Hooks/InsuranceCompanies";

const Footer = () => {
  const theme = useSelector(getTheme);
  const themeNavItems = theme.components.Footer?.navItems;
  const referralQueryParams = useSelector(getReferralQueryParams);
  const validatingReferralCodes = useSelector(getValidatingReferralCodes);
  const availableStates = useSelector(getAvailableStateCodes);
  const unitedNationalInsuranceCompany = useSelector(
    getStatesByInsuranceCompany(
      "United National Insurance Company",
      availableStates,
      true
    )
  );
  const pennPatriotInsuranceCompany = useSelector(
    getStatesByInsuranceCompany(
      "Penn Patriot Insurance Company",
      availableStates
    )
  );
  const diamondStateInsuranceCompany = useSelector(
    getStatesByInsuranceCompany(
      "Diamond State Insurance Company",
      availableStates
    )
  );

  useInsuranceCompanies();

  const navItems = (
    <Nav className="themed-footer__navigation">
      {themeNavItems &&
        !validatingReferralCodes &&
        themeNavItems.map(({ label, url, target }, index) => (
          <Nav.Item className="themed-footer__navigation-item" key={index}>
            <FooterLink
              href={url + referralQueryParams}
              target={target || "_blank"}
              rel="noopener"
            >
              {label}
            </FooterLink>
          </Nav.Item>
        ))}
    </Nav>
  );
  return (
    <StyledFooter>
      <div className="container">
        <div className="wrap">
          {navItems}
          <div className="themed-footer__legal">
            Coverage is underwritten by United National Insurance Company® in{" "}
            {unitedNationalInsuranceCompany.join(", ")}, which has a group
            rating of “A” (Excellent) by AM Best and a GBLI | Global Indemnity
            company.
            <br />
            Coverage is underwritten by Penn Patriot Insurance Company® in{" "}
            {pennPatriotInsuranceCompany.join(", ")}, which has a group rating
            of “A” (Excellent) by AM Best and a GBLI | Global Indemnity company.
            <br />
            Coverage is underwritten by Diamond State Insurance Company®,{" "}
            {diamondStateInsuranceCompany.join(", ")}, which has a group rating
            of “A” (Excellent) by AM Best and a GBLI | Global Indemnity company.
          </div>
          <div className="themed-footer__copyright">
            Copyright © {format(new Date(), "yyyy")} Global Indemnity Group. All
            rights reserved.
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
