import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import SelectList from "@gbli-events/common/src/Components/FormElements/SelectList";
import UnderwritingQuestionAlert from "./UnderwritingQuestionAlert";
import {
  FieldInputProps,
  FieldMetaProps,
} from "@gbli-events/common/src/Constants/ReduxFormPropTypes";
import { getTheme } from "src/Selectors/Shared";

const propTypes = {
  input: FieldInputProps,
  meta: FieldMetaProps,
  description: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const SelectQuestionField = ({
  input,
  meta,
  description,
  placeholder,
  options,
}) => {
  const selectOptions = options.map((option) => ({
    value: option,
    label: option,
  }));

  const theme = useSelector(getTheme);

  return (
    <div className="underwriting-questions__row">
      <div className="d-flex justify-content-between flex-nowrap underwriting-questions__field">
        <p className="underwriting-questions__text">{description}</p>
        <div className="underwriting-questions__select-container">
          <SelectList
            input={input}
            meta={meta}
            theme={theme}
            options={selectOptions}
            type="select"
            placeholder={placeholder}
          />
        </div>
      </div>
      {meta.dirty && meta.error && <UnderwritingQuestionAlert />}
    </div>
  );
};

SelectQuestionField.propTypes = propTypes;

export default SelectQuestionField;
