import CurrencyHelper from "../Helpers/CurrencyHelper";

export const getPCODescription = ({
  isCannabisIncluded,
  isDefaultGl,
  occurrence,
  PCOAmount,
}) => {
  if (isCannabisIncluded) {
    return {
      coverageDescription: "Not Included",
      limitName: "Product & Completed Operations",
      priceDescription: "Not Included",
    };
  } else if (isDefaultGl) {
    return {
      coverageDescription: "TBD",
      limitName: "Product & Completed Operations",
      priceDescription: "TBD",
    };
  } else {
    return {
      coverageDescription: "Included",
      limitName: "Product & Completed Operations",
      priceDescription: new CurrencyHelper(PCOAmount).formatDollars(),
    };
  }
};

export const getPersonalAdLimitDescription = ({
  isDefaultGl,
  occurrence,
  advertisingAmount,
}) => {
  if (isDefaultGl) {
    return {
      coverageDescription: "TBD",
      limitName: "Personal Advertising",
      priceDescription: "TBD",
    };
  } else {
    return {
      coverageDescription: new CurrencyHelper(occurrence).dollars(),
      limitName: `Personal Advertising`,
      priceDescription: new CurrencyHelper(advertisingAmount).formatDollars(),
    };
  }
};

export const getMedicalDescription = ({
  isDefaultGl,
  medicalLimit,
  medicalAmount,
}) => {
  if (isDefaultGl) {
    return {
      coverageDescription: "TBD",
      limitName: "Medical Coverage",
      priceDescription: "TBD",
    };
  } else {
    return {
      coverageDescription: new CurrencyHelper(medicalLimit).dollars(),
      limitName: "Medical Coverage",
      priceDescription: new CurrencyHelper(medicalAmount).formatDollars(),
    };
  }
};

export const getDmgToRentedPremisesDescription = ({
  isCoverageUnknown,
  coverageAmount,
  coverageLimit,
}) => {
  if (isCoverageUnknown) {
    return {
      coverageDescription: "TBD",
      limitName: "Damage to Rented Premises",
      priceDescription: "TBD",
    };
  } else {
    return {
      coverageDescription: new CurrencyHelper(coverageLimit).dollars(),
      limitName: `Damage to Rented Premises`,
      priceDescription: new CurrencyHelper(coverageAmount)
        .convertFromInt()
        .formatDollars(),
    };
  }
};

export const getHostLiquorDescription = ({
  hostLiquorExcluded,
  eventDailyGuestsUnknown,
  hostLiquorAmount,
}) => {
  if (hostLiquorExcluded) {
    return {
      coverageDescription: "Not Included",
      limitName: "Host Liquor",
      priceDescription: "Not Included",
    };
  } else if (eventDailyGuestsUnknown) {
    return {
      coverageDescription: "TBD",
      limitName: "Host Liquor",
      priceDescription: "TBD",
    };
  } else {
    return {
      coverageDescription: "Included",
      limitName: "Host Liquor",
      priceDescription: new CurrencyHelper(hostLiquorAmount)
        .convertFromInt()
        .formatDollars(),
    };
  }
};

export const getTerrorismDescription = ({
  isPristine,
  isSelected,
  terrorismAmount,
  terrorismRate = null,
}) => {
  return {
    coverageDescription: isPristine
      ? ""
      : isSelected
      ? "Purchased"
      : "Declined",
    limitName: `Terrorism`,
    priceDescription:
      !isPristine && !isSelected
        ? "Declined"
        : isSelected && (terrorismAmount || terrorismRate)
        ? new CurrencyHelper(terrorismAmount || terrorismRate)
            .convertFromInt()
            .formatDollars()
        : "Not Selected",
    terrorismRate:
      isPristine && terrorismRate
        ? new CurrencyHelper(terrorismRate).convertFromInt().formatDollars()
        : null,
  };
};

export const getPrimaryNoncontributoryEndorsementDescription = () => {
  return {
    coverageDescription: "Included",
    limitName: "Primary Noncontributory Endorsement",
    priceDescription: new CurrencyHelper(0).formatDollars(),
  };
};

export const getWaiverOfSubrogationDescription = (ACWaiverOfTransferRights) => {
  if (ACWaiverOfTransferRights) {
    return {
      coverageDescription: "Included",
      limitName: "Waiver of Subrogation",
      priceDescription: new CurrencyHelper(0).formatDollars(),
    };
  } else {
    return {
      coverageDescription: "Not Included*",
      limitName: "Waiver of Subrogation",
      priceDescription: "Not Included",
      additionalInfo:
        "*Can be added for no charge. Please contact us after purchase.",
    };
  }
};
