import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Field, change } from "redux-form";
import {
  GBLI_EMAIL_EVENTS,
  GBLI_PHONE_EVENTS_TITLE,
} from "@gbli-events/common/src/Constants/shared";
import { formName } from "src/Constants/constants";
import { connect, useSelector } from "react-redux";
import FormStepNavigation from "../FormStepNavigation";
import EventCard from "./EventCard";
import {
  required,
  requiredEvent,
} from "@gbli-events/common/src/Helpers/validators";
import WeddingImage from "@gbli-events/common/src/Assets/Images/wedding-md.jpg";
import DonationImage from "@gbli-events/common/src/Assets/Images/donation-md.jpg";
import BirthdayCandlesImage from "@gbli-events/common/src/Assets/Images/birthday-candles-md.jpg";
import BabyShowerImage from "@gbli-events/common/src/Assets/Images/baby-shower-md.jpg";
import QuiceaneraImage from "@gbli-events/common/src/Assets/Images/quiceanera-md.jpg";
import IndividualVendorImage from "@gbli-events/common/src/Assets/Images/individual-vendor-md.jpg";
import RadioFieldGroup from "@gbli-events/common/src/Components/FormElements/RadioFieldGroup/RadioFieldGroup";
import Alert from "@gbli-events/common/src/Components/Themed/Alert";

import {
  isEventSelectionPageValid,
  eventCannabisError,
  eventRiskClass3Error,
  eventRiskClass9Error,
} from "src/Selectors/Event";
import {
  getVenueStateCannabisErrorMessage,
  getMainVenueStateName,
} from "src/Selectors/Venue";
import { getTheme, getThemedSelectStyles } from "src/Selectors/Shared";
import { eventPropType } from "src/Models/EventTypeModel";
import NavContinueButton from "../NavContinueButton";
import EventTypeSelect from "./EventTypeSelect";

const mapStateToProps = (state) => ({
  eventTypes: state.formPage.eventTypes,
  selectedEventType: state.form.application.values.eventType,
  cannabis: state.form.application.values.cannabis,
  cannabisError: eventCannabisError(state),
  eventRiskClass3Error: eventRiskClass3Error(state),
  eventRiskClass9Error: eventRiskClass9Error(state),
  isEventSelectionPageValid: isEventSelectionPageValid(state),
  venueStateCannabisErrorMessage: getVenueStateCannabisErrorMessage(state),
  mainVenueStateName: getMainVenueStateName(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectEventType: (eventType) =>
    dispatch(
      change(formName, "eventType", {
        label: eventType.name,
        value: eventType,
      })
    ),
});

const propTypes = {
  eventTypes: PropTypes.arrayOf(eventPropType).isRequired,
  selectedEventType: eventPropType,
  cannabis: PropTypes.string,
  cannabisError: PropTypes.bool.isRequired,
  eventRiskClass3Error: PropTypes.bool.isRequired,
  eventRiskClass9Error: PropTypes.bool.isRequired,
  isEventSelectionPageValid: PropTypes.bool.isRequired,
  selectEventType: PropTypes.func.isRequired,
  venueStateCannabisErrorMessage: PropTypes.string.isRequired,
  mainVenueStateName: PropTypes.string,
};

const EventSelection = ({
  eventTypes,
  selectEventType,
  selectedEventType,
  cannabisError,
  eventRiskClass3Error,
  eventRiskClass9Error,
  isEventSelectionPageValid,
  venueStateCannabisErrorMessage,
  mainVenueStateName,
}) => {
  const eventCards = [
    {
      identifier: "wedding-ceremony-reception",
      cardTitle: "Wedding Ceremony & Reception",
      image: WeddingImage,
    },
    {
      identifier: "birthday-party-no-admission-charge-invite-only",
      cardTitle: "Birthday Party",
      image: BirthdayCandlesImage,
    },
    {
      identifier: "quinceanera-no-admission-charge-invite-only",
      cardTitle: "Quinceanera",
      image: QuiceaneraImage,
    },
    {
      identifier: "charity-party-dance-dinner-lunch",
      cardTitle: "Charity Party, Dance, Dinner or Lunch",
      image: DonationImage,
    },
    {
      identifier: "baby-or-wedding-shower",
      cardTitle: "Wedding or Baby Shower",
      image: BabyShowerImage,
    },
    {
      identifier: "individual-vendor",
      cardTitle: "Individual Vendor",
      image: IndividualVendorImage,
    },
  ].map(({ identifier, image, cardTitle }) => {
    const eventType = eventTypes.find(
      (eventType) => identifier === eventType.identifier
    );

    return eventType ? (
      <div key={identifier} className="col-12 col-lg-4">
        <EventCard
          eventType={eventType}
          cardTitle={cardTitle}
          image={image}
          selected={selectedEventType?.identifier === identifier}
          onClick={() => selectEventType(eventType)}
        />
      </div>
    ) : (
      <Fragment key={identifier} />
    );
  });

  const selectOptions = eventTypes.map((eventType) => ({
    label: eventType.name,
    value: eventType,
  }));

  const theme = useSelector(getTheme);
  const selectStyles = useSelector(getThemedSelectStyles);

  return (
    <div className="event-selection">
      <div className="container mb-4">
        <FormStepNavigation />
        <div className="page-heading">
          <h2>Choose the type of event you need to insure.</h2>
          <h3>
            Coverage for Fairs, Festivals, Sporting Events, Concerts,
            Fundraisers, and More!
          </h3>
        </div>
        {eventRiskClass3Error && (
          <Alert variant="error" className="mt-3">
            <i className="far fa-exclamation-circle alert__icon" />
            <div className="alert__text">
              Sorry, the event type you have selected cannot be bound online.
              Please contact us at{" "}
              <span className="text-nowrap">{GBLI_PHONE_EVENTS_TITLE}</span> or{" "}
              {GBLI_EMAIL_EVENTS} for further assistance.
            </div>
          </Alert>
        )}
        {eventRiskClass9Error && (
          <Alert variant="error" className="mt-3">
            <i className="far fa-exclamation-circle alert__icon" />
            <div className="alert__text">
              Sorry, we can't underwrite this event type. Please contact your
              local agent or broker for further assistance.
            </div>
          </Alert>
        )}
        <Field
          name="eventType"
          options={selectOptions}
          styles={selectStyles}
          theme={theme}
          validate={requiredEvent}
          component={EventTypeSelect}
        />
        <div className="event-selection__card-container">
          <div className="row">{eventCards}</div>
          {cannabisError && (
            <Alert variant="error" className="mt-3">
              <i className="far fa-exclamation-circle alert__icon" />
              <div className="alert__text">
                Sorry, the event type you have selected cannot be bound online
                with cannabis coverage. Please contact us at{" "}
                <span className="text-nowrap">{GBLI_PHONE_EVENTS_TITLE}</span>{" "}
                or {GBLI_EMAIL_EVENTS} for further assistance.
              </div>
            </Alert>
          )}
          {venueStateCannabisErrorMessage.length > 0 && (
            <Alert variant="error" className="mt-3">
              <i className="far fa-exclamation-circle alert__icon" />
              <div className="alert__text">
                {venueStateCannabisErrorMessage}
              </div>
            </Alert>
          )}
        </div>
        <div className="event-selection__cannabis">
          <p className="text-center mb-4 mb-lg-2">
            Will cannabis or cannabis-related products be sampled, sold,
            displayed, distributed and/or consumed at this event?
          </p>
          <RadioFieldGroup
            className="text-center"
            controlName="cannabis"
            validators={[required]}
            options={[
              { label: "Yes", value: "1" },
              { label: "No", value: "0" },
            ]}
          />
        </div>
        <NavContinueButton disabled={!isEventSelectionPageValid} />
      </div>
    </div>
  );
};

EventSelection.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(EventSelection);
