import styled from "styled-components";
import {
  getMediaBreakpointDown,
  getMediaBreakpointUp,
  getThemeColor,
} from "@gbli-events/common/src/Helpers/themes";

const btnHighlight = ({ theme }) => `
    background-color: ${theme.components.ButtonToggle?.backgroundColorActive};
    border: 2px solid ${theme.components.ButtonToggle?.borderColorActive};
    color: ${theme.components.ButtonToggle?.colorActive};
`;

const StyledYesNoQuestionField = styled.div`
  .underwriting-questions__controls {
    display: block;

    ${({ theme }) => getMediaBreakpointDown(theme.breakpoints.sm)} {
      margin-top: 1rem;
    }

    & .form-error {
      ${({ theme }) => getMediaBreakpointUp(theme.breakpoints.md)} {
        padding-left: 1.5rem;
      }
    }

    & .col-auto:first-child {
      & .custom-radio {
        ${({ theme }) => getMediaBreakpointDown(theme.breakpoints.md)} {
          padding-left: 0;
        }
      }
    }

    & .custom-control-input {
      & + .btn {
        background-color: ${({ theme }) =>
          theme.components.ButtonToggle?.backgroundColor};
        border: 1px solid
          ${({ theme }) => theme.components.ButtonToggle?.borderColor};
        color: ${({ theme }) => theme.components.ButtonToggle?.color};
        border-radius: ${({ theme }) =>
          theme.components.ButtonToggle?.borderRadius};
      }
      &:focus + .btn {
        outline: 2px solid
          ${({ theme }) => theme.components.ButtonToggle?.borderColor};
      }
      &:checked {
        & + .btn {
          ${btnHighlight}

          &--error {
            color: ${({ theme }) => getThemeColor(theme, "error")};
            background-color: ${({ theme }) =>
              getThemeColor(theme, "error", "light")};
            border-color: ${({ theme }) => getThemeColor(theme, "error")};
            border-width: 1px;
          }
        }
      }

      &:hover:not(:checked) {
        & + .btn {
          ${btnHighlight}
        }
      }
    }
  }
`;

export default StyledYesNoQuestionField;
