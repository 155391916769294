/**
 *
 * Reducer for all app custom behaviour.
 *
 */
import { UPDATE_SYNC_ERRORS, CHANGE } from "redux-form/lib/actionTypes";
import {
  FACILITY_CODE_PARAM,
  formName,
  SOURCE_CODE_PARAM,
  VENUE_CODE_PARAM,
  PRODUCER_CODE_PARAM,
} from "../Constants/constants";
import {
  allInitialState,
  ephemeralInitialState,
} from "../Constants/formPageInitialState";
import { getById } from "../Helpers/EventTypes";
import RouteParser from "../Helpers/RouteParser";
import {
  EVENT_TYPE_FORM,
  EDIT_FORM,
  EVENT_SELECT_FORM,
  EVENT_SELECT_SECONDARY_FORM,
  VENUE_FORM,
  CONFIRM_FORM,
  BASIC_COVERAGE_FORM,
  EVENT_FORM,
  ADDITIONAL_COVERAGES_FORM,
  INSURED_FORM,
  INSURANCE_CONTACT_FORM,
  PAYMENT_INFO_FORM,
  BACK_FORM,
  SELECT_PLACE,
  SUMMARY_TOGGLE,
  SUMMARY_MOBILE_TOGGLE,
  SUMMARY_HEIGHT,
  SCREEN_SIZE,
  API_ERROR,
  UPDATE_BLOCKED_VENUE,
  UPDATE_GENERIC_SERVER_ERROR,
  SET_EVENT_TYPES,
  SET_US_STATES,
  FETCH_EVENT_TYPES_FAILURE,
  FETCH_US_STATES_FAILURE,
  CREDITCARD_TOKEN_RESPONSE,
  START_LOADING,
  SET_FETCHING_VENUE_PRESETS,
  SET_VENUE_PRESETS,
  DEFAULT_ADDITIONAL_COVERAGES_STATUS,
  DEFAULT_BASIC_COVERAGE_OPTIONS,
  END_LOADING,
  SET_TAX_REGIONS,
  UPDATE_VENUE_CODE_VALIDITY,
  RESET_VENUE_CODE_VALIDATION,
  SELECT_PLACE_BY_VENUE_CODE,
  CLEAR_SELECT_PLACE,
  RESET_SPECIAL_TAX_FORM_FLAGS,
  SET_UTC_OFFSET,
  PRESERVE_HAS_REGIONAL_TAX,
  RESET_SELECTED_PLACE,
  HANDLE_LOCATION_SEARCH,
  FETCH_REFERRAL_FACILITY,
  SET_REFERRAL_FACILITY,
  CLEAR_VENUE_REFERRAL,
  UPDATE_CERT_HOLDERS,
  SET_SELECTED_VENUE_CODE,
  SET_VALID_VENUE_ADDRESS_AND_STATE,
  UPDATE_MANUAL_ADDRESS,
  UPDATE_MANUAL_ADDRESS_CITY,
  VENUE_CODE_REFERRAL_OVERRIDE,
  GET_ADDITIONAL_CERT_HOLDERS,
  RESET_APPLICATION_FORM,
  SET_REFERRAL_PRODUCER,
  SET_REFERRAL_VENUE,
  FETCH_REFERRAL_PRODUCER,
  COMPLETED_ORDER,
  FETCHING_TAX_INFO,
  FETCHING_TAX_INFO_SUCCESS,
  FETCHING_TAX_INFO_ERROR,
  UPDATE_IS_CANNABIS_PREVENTED,
  SET_AUTH_USER,
} from "../Actions/actions";
import {
  FETCH_QUOTE_SUCCESS,
  FETCH_QUOTE,
  FETCH_QUOTE_ERROR,
} from "../Actions/quote";
import {
  SELECT_EVENT_TYPE,
  FETCH_EVENT_TYPE_VALIDATION_SUCCESS,
  FETCH_EVENT_TYPE_VALIDATION_ERROR,
} from "src/Actions/events";
import {
  SET_IS_KENTUCKY_CITY,
  SET_KENTUCKY_CITY,
  SET_HAS_LOOKED_UP_TAX,
} from "../Actions/venue";
import { SET_NEED_OPTIONAL_VENUE } from "src/Actions/optionalVenues";
import initialFormValues from "../Constants/initialFormValues";
import { BY_MANUAL_ADDRESS } from "../Constants/venueSearchTypes";
import {
  FETCH_UNDERWRITING_QUESTIONS_FAILURE,
  SET_UNDERWRITING_QUESTIONS,
} from "src/Actions/underwritingQuestions";
import { SET_BOILERPLATE_REFERRAL } from "src/Actions/boilerplate";
import {
  ROUTE_GUARD_BLOCKED,
  PAGE_NOT_FOUND,
} from "@gbli-events/common/src/Actions/shared";
import {
  FETCH_INSURANCE_COMPANIES,
  FETCH_INSURANCE_COMPANIES_ERROR,
  FETCH_INSURANCE_COMPANIES_SUCCESS,
} from "src/Actions/insuranceCompany";
import { SET_THEME } from "@gbli-events/common/src/Actions/themes";
import { allLimits, GLL_1 } from "@gbli-events/common/src/Constants/limits";
import { PRODUCER_CODE_REGEX } from "@gbli-events/common/src/Constants/codes";
import { additionalInsuredTypes } from "../Constants/additionalInsuredTypes";
import { isEmpty } from "lodash";
import uniqid from "uniqid";

// What form are we on (use the form name constant)
const getStateFromName = (name) => {
  switch (name) {
    case EVENT_TYPE_FORM:
      return "eventType";
    case EVENT_SELECT_FORM:
      return "eventSelect";
    case EVENT_SELECT_SECONDARY_FORM:
      return "eventSelectSecondary";
    case VENUE_FORM:
      return "venue";
    case BASIC_COVERAGE_FORM:
      return "basicCoverage";
    case EVENT_FORM:
      return "aboutEvent";
    case ADDITIONAL_COVERAGES_FORM:
      return "additionalCoverages";
    case INSURED_FORM:
      return "insured";
    case INSURANCE_CONTACT_FORM:
      return "insuranceContact";
    case PAYMENT_INFO_FORM:
      return "paymentInfo";
    default:
      throw new Error("Invalid form name");
  }
};

// If we are on form part x, what is the next form part?
// Ie if you are on the first event select, the next one is the second event select
const getNextForm = (name) => {
  switch (name) {
    case EVENT_SELECT_FORM:
      return "eventSelectSecondary";
    case EVENT_SELECT_SECONDARY_FORM:
      return "venue";
    case VENUE_FORM:
      return "basicCoverage";
    case BASIC_COVERAGE_FORM:
      return "aboutEvent";
    case EVENT_FORM:
      return "additionalCoverages";
    case ADDITIONAL_COVERAGES_FORM:
      return "insured";
    case INSURED_FORM:
      return "insuranceContact";
    case INSURANCE_CONTACT_FORM:
      return "paymentInfo";
    default:
      return null;
  }
};

const isEventSelectSecondaryFormResponsesValid = (state) => {
  const event = state.eventType.selection;
  if (event) {
    const lossClaims =
      event.lossClaims === true
        ? state.eventSelectSecondary.lossClaims === false
        : true;
    const security =
      event.security === true
        ? state.eventSelectSecondary.security === false
        : true;
    const promoter =
      event.promoter === true
        ? state.eventSelectSecondary.promoter === false
        : true;
    return lossClaims && security && promoter;
  }
  return false;
};

// When a form field is modified, react to changes. E.g. if the event type is
// selected, update the locally stored value for that selection
const handleFormChange = (state, field, value) => {
  const updated = { ...state };
  const getInsuredRenterType = () => {
    if (!updated.insured.insuredRenter) {
      updated.insured.insuredRenterType = "Person";
    } else {
      updated.insured.insuredRenterType = updated.insured.insuredCompanyType;
    }
  };
  switch (field) {
    case "venue":
      updated.venue.selectedPlaceId =
        ephemeralInitialState().venue.selectedPlaceId;
      updated.venue.selectedPlaceAddress =
        ephemeralInitialState().venue.selectedPlaceAddress;
      updated.venue.selectedPlaceAddressComponents =
        ephemeralInitialState().venue.selectedPlaceAddressComponents;
      updated.venue.hasRegionalTax = false;
      updated.venue.isFederalEntity = true;
      updated.venue.isKentuckyEntity = true;
      return updated;
    case "manualVenueCity":
      updated.venue.hasRegionalTax = false;
      updated.venue.isFederalEntity = true;
      updated.venue.isKentuckyEntity = true;
      return updated;
    case "manualVenueState":
      updated.venue.hasRegionalTax = false;
      updated.venue.isFederalEntity = true;
      updated.venue.isKentuckyEntity = true;
      return updated;
    case "federalEntity":
      updated.venue.isFederalEntity = value === "yes";
      return updated;
    case "kentuckyStateEntity":
      updated.venue.isKentuckyEntity = value === "yes";
      return updated;
    case "generalLiabilityCoverage":
      updated.basicCoverage.coverage = value;
      return updated;
    case "eventTypeSelect":
      updated.eventSelect.eventTypeSelect = value;
      return updated;
    case "eventTypeRadio":
      updated.eventType.selection = getById(value, updated.eventTypes);
      return updated;
    case "insuredRenter":
      updated.insured.insuredRenter = value === "company";
      getInsuredRenterType();
      return updated;
    case "insuredCompanyType":
      updated.insured.insuredCompanyType = value;
      getInsuredRenterType();
      return updated;
    case "contactSame":
      updated.insuranceContact.contactSame = value === "1";
      return updated;
    case "contactRenter":
      updated.insuranceContact.contactRenter = value === "company";
      return updated;
    case "payeeSame":
      updated.paymentInfo.payeeSame = value === "yes";
      return updated;
    case "payeeRenter":
      updated.paymentInfo.payeeRenter = value === "company";
      return updated;
    case "lossClaims":
      updated.eventSelectSecondary.lossClaims = value === "yes";
      updated.eventSelectSecondary.showAlert =
        updated.eventSelectSecondary.lossClaims ||
        updated.eventSelectSecondary.promoter ||
        updated.eventSelectSecondary.security ||
        false;
      updated.eventSelectSecondary.valid =
        isEventSelectSecondaryFormResponsesValid(state);
      return updated;
    case "promoter":
      updated.eventSelectSecondary.promoter = value === "yes";
      updated.eventSelectSecondary.showAlert =
        updated.eventSelectSecondary.lossClaims ||
        updated.eventSelectSecondary.promoter ||
        updated.eventSelectSecondary.security ||
        false;
      updated.eventSelectSecondary.valid =
        isEventSelectSecondaryFormResponsesValid(state);
      return updated;
    case "security":
      updated.eventSelectSecondary.security = value === "yes";
      updated.eventSelectSecondary.showAlert =
        updated.eventSelectSecondary.lossClaims ||
        updated.eventSelectSecondary.promoter ||
        updated.eventSelectSecondary.security ||
        false;
      updated.eventSelectSecondary.valid =
        isEventSelectSecondaryFormResponsesValid(state);
      return updated;
    case "cannabis":
      if (updated.eventType.selection) updated.eventType.isValidating = true;
      return updated;
    default:
      break;
  }
  return state;
};

// Opens up a form
const editForm = (state, value) => {
  const updated = { ...state };
  const form = getStateFromName(value);
  updated[form].confirmed = false;

  updated.formPartOne.complete =
    updated.venue.confirmed &&
    updated.basicCoverage.confirmed &&
    updated.aboutEvent.confirmed &&
    updated.additionalCoverages.confirmed;

  updated.formPartTwo.complete =
    updated.insured.confirmed &&
    updated.insuranceContact.confirmed &&
    updated.paymentInfo.confirmed;

  return updated;
};

// User clicked a back button on some form. (re)set some values
// This only happens on EVENT_SELECT_SECONDARY_FORM, at the moment.
const backForm = (state, value) => {
  const updated = { ...state };
  if (value === EVENT_SELECT_SECONDARY_FORM) {
    const form = getStateFromName(EVENT_SELECT_SECONDARY_FORM);
    const prev = getStateFromName(EVENT_SELECT_FORM);

    updated[form].confirmed = false;
    updated[form].enabled = false;

    updated[prev].confirmed = false;
    updated[prev].enabled = true;

    // hide alert (error msg) on secondary event select
    updated[form].showAlert = false;

    // reset eventType.selection state
    const eventType = getStateFromName(EVENT_TYPE_FORM);
    updated[eventType].selection = null;
  }
  return updated;
};

// Sets confirmed and closes form
// Enables next form if applicable
const confirmForm = (state, value) => {
  const form = getStateFromName(value);
  const updated = { ...state };
  updated[form].confirmed = true;
  const next = getNextForm(value);

  // Set eventType.selection if select is used
  if (value === "POPUP_SELECT_FORM") {
    const eventType = getStateFromName("EVENT_TYPE_FORM");
    if (updated[form].eventTypeSelect) {
      updated[eventType].selection = getById(
        updated[form].eventTypeSelect,
        state.eventTypes
      );
    }
  }

  // If Venue is confirmed, make sure Additional Coverages is NOT, so 'Checkout' button does not show
  if (form === "venue") {
    updated.additionalCoverages.confirmed = false;
  }

  // Get next and enable
  if (next) {
    updated[next].enabled = true;
  }

  updated.formPartOne.complete =
    updated.venue.confirmed &&
    updated.basicCoverage.confirmed &&
    updated.aboutEvent.confirmed &&
    updated.additionalCoverages.confirmed;

  updated.formPartTwo.complete =
    updated.insured.confirmed &&
    updated.insuranceContact.confirmed &&
    updated.paymentInfo.confirmed;

  return updated;
};

// Venue was selected
const selectPlace = (
  state,
  placeId,
  address,
  addressComponents,
  utcOffset,
  knownVenueData
) => {
  const updated = { ...state };
  updated.venue.selectedPlaceAddressComponents = addressComponents;

  if (knownVenueData) {
    const { facility, venue: knownVenue, eventTypes } = knownVenueData;

    updated.venue.selectedPlaceAddressComponents.companyName =
      knownVenue.companyName;
    updated.venue.selectedPlaceAddressComponents.address1 = knownVenue.address1;
    updated.venue.selectedPlaceAddressComponents.city = knownVenue.city;
    updated.venue.selectedPlaceAddressComponents.state = knownVenue.state;
    updated.venue.selectedPlaceAddressComponents.country = knownVenue.country;
    updated.venue.selectedPlaceAddressComponents.zip = knownVenue.zip;
    updated.venue.producer = {
      producerName: facility.producerName,
      producerLogoUrl: facility.producerLogo,
    };
    updated.venue.facilityLogoUrl = facility.facilityLogo;
    updated.venue.eventTypes = eventTypes;
  } else {
    updated.venue.selectedPlaceAddressComponents.companyName =
      addressComponents.companyName !== addressComponents.address1 ||
      (addressComponents.companyName &&
        addressComponents.address1 &&
        addressComponents.companyName === addressComponents.address1 &&
        !(addressComponents.companyName.charAt(0) < 10))
        ? addressComponents.companyName
        : null;
  }

  updated.venue.selectedPlaceId = placeId;
  updated.venue.selectedPlaceAddress = address;
  updated.venue.selectedPlaceUtcOffset = utcOffset;
  updated.venue.validVenue = true;
  return updated;
};

const getInputCodesFromQueryParams = (params) => {
  const rawParams = params.toLowerCase();
  const urlParams = new URLSearchParams(rawParams);
  const sourceCode = urlParams.get(SOURCE_CODE_PARAM);
  const venueCode = urlParams.get(VENUE_CODE_PARAM);
  const facilityCode = urlParams.get(FACILITY_CODE_PARAM);
  const producerCode = urlParams.get(PRODUCER_CODE_PARAM);

  return [
    sourceCode && /^([a-z0-9]){1,10}$/i.test(sourceCode) ? sourceCode : "",
    venueCode && /^[a-z0-9]{1,4}-[0-9]{3,5}/i.test(venueCode) ? venueCode : "",
    facilityCode && /^([a-z0-9]){1,4}$/i.test(facilityCode) ? facilityCode : "",
    producerCode && PRODUCER_CODE_REGEX.test(producerCode) ? producerCode : "",
  ];
};

const filterVenueSelectInputTypes = (state) => {
  const updated = { ...state };
  return ephemeralInitialState().venueSelectInputOptions.filter(
    (type) => updated.referral.facilityCode || type.value !== BY_MANUAL_ADDRESS
  );
};

const handleLocationSearch = (state, search) => {
  const updated = { ...state };
  [
    updated.referral.sourceCode,
    updated.referral.venueCode,
    updated.referral.facilityCode,
  ] = getInputCodesFromQueryParams(search);

  // If there is a facility code, then add manual entry as a selection type
  updated.venueSelectInputOptions = filterVenueSelectInputTypes(updated);

  return updated;
};

// Section: Determine whether a form part is valid based on Redux Form's errors
const isVenueFormValid = (errors) => {
  return !errors.venue && !errors.federalEntity && !errors.kentuckyStateEntity;
};

const isManualAddressValid = (errors) => {
  return (
    !errors.manualVenueName &&
    !errors.manualVenueAddress1 &&
    !errors.manualVenueCity &&
    !errors.manualVenueState &&
    !errors.manualVenueCountry &&
    !errors.manualVenueZip
  );
};

const isBasicCoverageFormValid = (errors) => {
  return !errors.generalLiabilityCoverage;
};

const isAboutEventFormValid = (errors) => {
  let eventPerformersCountStatus = !errors.eventPerformersCount;
  let eventGoodsStatus = !errors.eventGoods;
  let eventFoodStatus = !errors.eventFood;
  let eventExhibitionStatus = !errors.eventExhibition;

  if (!errors.eventPerformers) {
    eventPerformersCountStatus = true;
    eventGoodsStatus = true;
    eventFoodStatus = true;
    eventExhibitionStatus = true;
  }

  return (
    !errors.eventName &&
    !errors.eventDateRange &&
    !errors.daysOfWeekField &&
    !errors.eventDates &&
    !errors.eventDailyGuests &&
    !errors.performersFrequency &&
    !errors.goodsVendorsFrequency &&
    !errors.foodVendorsFrequency &&
    !errors.exhibitorsFrequency &&
    !errors.performersList &&
    !errors.foodVendorsList &&
    !errors.goodsVendorsList &&
    !errors.exhibitorsList &&
    (eventPerformersCountStatus ||
      eventGoodsStatus ||
      eventFoodStatus ||
      eventExhibitionStatus)
  );
};

const isAdditionalCoveragesFormValid = () => {
  return true;
};

const isInsuredFormValid = (errors) => {
  const insuredIndividual = !errors.insuredFirstName && !errors.insuredLastName;
  const insuredCorp = !errors.insuredCompany && !errors.insuredCompanyType;
  const insuredInfo =
    !errors.insuredAddress &&
    !errors.insuredCity &&
    !errors.insuredState &&
    !errors.insuredZip;

  return (
    // Toggle validation check based on person or company.
    // Requires field-level validation rule "personCompany" on <RenterField /> component
    (!errors.insuredRenter ? insuredIndividual : insuredCorp) && insuredInfo
  );
};

const isInsuranceContactFormValid = (errors) => {
  const contactPhoneEmail = !errors.contactPhone && !errors.contactEmail;
  const contactFullName = !errors.contactFirstName && !errors.contactLastName;

  const contactInfo =
    contactFullName &&
    !errors.contactCompany &&
    !errors.contactAddress &&
    !errors.contactCity &&
    !errors.contactState &&
    !errors.contactZip;

  if (!errors.contactSame) {
    return contactFullName && contactPhoneEmail;
  }

  return contactPhoneEmail && contactInfo;
};

const isPaymentInfoFormValid = (errors) => {
  const paymentInfo =
    !errors.payeeCardNum &&
    !errors.payeeCardName &&
    !errors.payeeCardExp &&
    !errors.payeeCardCVV;

  const contactInfo =
    !errors.payeeAddress &&
    !errors.payeeCity &&
    !errors.payeeState &&
    !errors.payeeZip;

  if (!errors.payeeSame) return paymentInfo;

  return (
    // Toggle validation check based on person or company.
    // Requires field-level validation rule "personCompany" on <RenterField /> component
    (!errors.payeeRenter
      ? !errors.payeeFirstName && !errors.payeeLastName
      : !errors.payeeCompany) &&
    paymentInfo &&
    contactInfo
  );
};

const isEventSelectFormValid = () => {
  return true;
};

const isEventSelectSecondaryFormValid = (errors, state) => {
  return (
    isEventSelectSecondaryFormResponsesValid(state) &&
    !errors.lossClaims &&
    !errors.promoter &&
    !errors.security
  );
};

// Is the entire form valid (use above)
const checkFormValidity = (state, errors) => {
  const updated = { ...state };
  updated.eventSelect.valid = isEventSelectFormValid();
  updated.eventSelectSecondary.valid = isEventSelectSecondaryFormValid(
    errors,
    updated
  );
  updated.venue.validVenue = isVenueFormValid(errors);
  updated.venue.validManualAddress = isManualAddressValid(errors);
  updated.basicCoverage.valid = isBasicCoverageFormValid(errors);
  updated.aboutEvent.valid = isAboutEventFormValid(errors);
  updated.additionalCoverages.valid = isAdditionalCoveragesFormValid();
  updated.insured.valid = isInsuredFormValid(errors);
  updated.insuranceContact.valid = isInsuranceContactFormValid(errors);
  updated.paymentInfo.valid = isPaymentInfoFormValid(errors);
  return updated;
};

// Summary section display should be toggled
const toggleSummaryInfo = (state) => {
  const updated = { ...state };
  updated.summary.isOpen = !updated.summary.isOpen;
  return updated;
};

// Summary section display should be toggled
const toggleSummaryMobile = (state, show) => {
  const updated = { ...state };
  updated.summary.mobileShow = show;
  return updated;
};

// Get height of summary container
const summaryHeight = (state, sHeight) => {
  const updated = { ...state };
  updated.summary.height = sHeight;
  return updated;
};

const setLoading = (state, form, loading) => {
  const updated = { ...state };

  if (form === PAYMENT_INFO_FORM) {
    updated.paymentInfo.isLoading = loading;
    if (loading) {
      updated.paymentInfo.errorMessage = "";
    }
  }

  if (form === SUMMARY_TOGGLE) {
    updated.summary.isLoading = loading;
    if (loading) {
      updated.summary.errorResponse = false;
    }
  }

  if (form === GET_ADDITIONAL_CERT_HOLDERS) {
    updated.summary.isGettingCertHolders = loading;
  }

  return updated;
};

const startLoading = (state, form) => setLoading(state, form, true);
const endLoading = (state, form) => setLoading(state, form, false);

// Determine summary.isOpen based on page and screen width
const handleSummaryOpen = (state) => {
  const updated = { ...state };
  const routeParser = RouteParser.create(state.currentLocation);
  if (routeParser.selectedPage() === RouteParser.formParts.application) {
    if (updated.summary.mobileDisplay) {
      updated.summary.isOpen =
        updated.formPartOne.complete || !updated.summary.mobileDisplay;
    }
    if (updated.formPartOne.complete) updated.summary.isOpen = true;
  } else if (routeParser.selectedPage() === RouteParser.formParts.checkout) {
    if (updated.summary.mobileDisplay) {
      updated.summary.isOpen =
        (updated.formPartOne.complete && updated.formPartTwo.complete) ||
        !updated.summary.mobileDisplay;
    }
    if (updated.formPartOne.complete && updated.formPartTwo.complete)
      updated.summary.isOpen = true;
  }
  if (updated.summary.isOpen && updated.summary.mobileDisplay) {
    updated.summary.mobileShow = true;
  }
  return updated;
};

// const updateBasicCoverageOptions = (state) => {
//   const updated = { ...state };

//   if (!updated.basicCoverage.glLimit)
//     updated.basicCoverage.options = ['1000000', '2000000'];

//   return updated;
// };

const defaultBasicCoverageOptions = (state) => {
  const updated = { ...state };
  updated.basicCoverage.glLimit = GLL_1;
  updated.basicCoverage.options = allLimits;
  return updated;
};

// const getQuoteAdditionalCoverage = (additionalCoverageEstimate, type) =>
//   additionalCoverageEstimate.reduce(
//     (saved, current) =>
//       current.type === type ? parseInt(current.amount.amount, 10) : saved,
//     0,
//   );

// const updateSurcharge = (updated, quoteValues) => {
//   let federalSurcharge = 0;
//   let stateSurcharge = 0;
//   const { isFederalEntity } = updated.venue;
//   const { isKentuckyEntity } = updated.venue;
//   if (quoteValues.surcharges.length === 1) {
//     if (
//       (!isFederalEntity && isKentuckyEntity) ||
//       (!isFederalEntity && !isKentuckyEntity)
//     ) {
//       federalSurcharge = parseInt(quoteValues.surcharges[0].amount.amount, 10);
//     } else {
//       stateSurcharge = parseInt(quoteValues.surcharges[0].amount.amount, 10);
//     }
//   } else if (quoteValues.surcharges.length === 2) {
//     federalSurcharge = parseInt(quoteValues.surcharges[0].amount.amount, 10);
//     stateSurcharge = parseInt(quoteValues.surcharges[1].amount.amount, 10);
//   }
//   return { federalSurcharge, stateSurcharge };
// };

// When quote values come in, store them in state
const fetchQuote = (state) => ({
  ...state,
  quote: {
    ...state.quote,
    isFetching: true,
  },
});

const fetchQuoteSuccess = (state, quoteValues) => {
  const updated = {
    ...state,
    quote: {
      isFetching: false,
      quote: quoteValues,
      error: null,
    },
  };

  // TODO: REVISIT BELOW COMMENTED CODE THIS WHEN WE WORK ON CREATE ORDER SCREEN, causing error now

  // const basicCoverageAmt = parseInt(quoteValues.basicCoverageAmount.amount, 10);
  // const personalPropertyAmt = parseInt(
  //   quoteValues.additionalCoverages.reduce((saved, current) => {
  //     const amt =
  //       current.type === 'personal-property'
  //         ? parseInt(current.amount.amount, 10)
  //         : 0;
  //     return saved + amt;
  //   }, 0),
  //   10,
  // );

  // const liquor = getQuoteAdditionalCoverage(
  //   quoteValues.additionalCoverageEstimate,
  //   'liquor-liability',
  // );
  // const damageToRentedProperty = getQuoteAdditionalCoverage(
  //   quoteValues.additionalCoverageEstimate,
  //   'damage-to-rented-premises',
  // );

  // const surcharge = updateSurcharge(updated, quoteValues);

  // updated.priceValues = {
  //   basicCoverageAmount: basicCoverageAmt + personalPropertyAmt,
  //   liquorPerDay: liquor / quoteValues.numberOfDays,
  //   liquor,
  //   damageToRentedPropertyPerDay:
  //     damageToRentedProperty / quoteValues.numberOfDays,
  //   damageToRentedProperty,
  //   subtotal: parseInt(quoteValues.subtotal.amount, 10),
  //   federalSurcharge: surcharge.federalSurcharge,
  //   stateSurcharge: surcharge.stateSurcharge,
  //   total: parseInt(quoteValues.total.amount, 10),
  // };

  // updateBasicCoverageOptions(updated);

  return updated;
};

const fetchQuoteError = (state, error) => ({
  ...state,
  quote: {
    isFetching: false,
    quote: null,
    error,
  },
});

const isPaymentTokenValid = (token) => {
  return token != null && token !== "";
};

const updateCreditCardToken = (state, payload) => {
  const updated = { ...state };
  updated.paymentInfo.token = payload.token;
  const lastFour = payload.maskedPan.substring(payload.maskedPan.length - 4);
  updated.paymentInfo.lastFour = lastFour;
  updated.paymentInfo.cardType = payload.cardType;
  return updated;
};

const handleCreditCardTokenResponse = (state, action) => {
  const { token } = action.payload;
  const isValid = isPaymentTokenValid(token);
  let updated = { ...state };
  if (isValid) {
    updated = {
      ...updateCreditCardToken(updated, action.payload),
      ...confirmForm(updated, action.form),
      ...handleSummaryOpen(updated),
    };
    updated.paymentInfo.errorMessage = "";
  } else {
    // TODO: handle card token errors
    updated.paymentInfo.errorMessage = "Error validating payment information.";
  }
  updated.paymentInfo.isLoading = false;
  return { ...updated };
};

// Determine whether mobile
const mobileDisplayState = (state, width) => {
  const updated = { ...state };
  if (width < 768) {
    updated.summary.mobileDisplay = true;
  } else {
    updated.summary.mobileDisplay = false;
  }
  updated.summary.isOpen = !updated.summary.mobileDisplay;
  return updated;
};

const errorResponse = (state, error, form) => {
  if (
    error?.response?.status === 503 &&
    error?.response?.data?.message === "Maintenance"
  ) {
    return {
      ...state,
      maintenanceMode: true,
    };
  }
  const updated = { ...state };
  const offlineMessage = "Network error. Please try again.";

  if (form === "PAYMENT_INFO_FORM") {
    updated.paymentInfo.isLoading = false;
    updated.paymentInfo.errorMessage = error.response
      ? "Payment processing error. Please try again or refresh the page."
      : offlineMessage;
  }

  if (form === "SUMMARY_TOGGLE") {
    updated.summary.errorResponse = !!error.response;
  }

  return { ...state };
};

// Event types received, should be stored to be used in components
const setEventTypes = (state, data) => {
  const updated = { ...state };
  updated.eventTypes = data;
  return updated;
};

// Underwriting questions received, should be stored to be used in components
const setUnderwritingQuestions = (state, data) => {
  const updated = { ...state };
  updated.underwritingQuestions = data;
  return updated;
};

// List of US States received, should be stored to be used in components
const setUSStates = (state, data) => {
  const updated = { ...state };
  updated.usStates = data;
  return updated;
};

const setAuthUser = (state, data) => {
  const updated = { ...state };
  updated.authUser = data;
  return updated;
};

// Default Additional Coverages state if no venue specific presets
const defaultAdditionalCoverageStatus = (state) => {
  const updated = { ...state };
  updated.additionalCoverages = [];
  return updated;
};

// Clear previous Additional Coverage states for venue specific presets
const clearAdditionalCoverageStatus = (state) => {
  return defaultAdditionalCoverageStatus(state);
};

// Sets the limits available based on presets defined by some venues (see setVenuePresets below)
const setBasicCoverage = (state, data) => {
  const updated = { ...state };
  const [first] = data.venue.glLimits;

  updated.basicCoverage.glLimit = first;
  updated.basicCoverage.options = data.venue.glLimits;

  return updated;
};

// Sets the coverage options and settings based on presets defined by some venues (see setVenuePresets below)
const setAdditionalCoverage = (state, data) => {
  const updated = { ...state };

  // Reset Additional Coverages to default values
  clearAdditionalCoverageStatus(updated);

  updated.additionalCoverages = data.venue.additionalCoverage;

  return updated;
};

const setVenuePresets = (state, data) => {
  let updated = { ...state };
  updated = { ...setBasicCoverage(updated, data) };
  updated = { ...setAdditionalCoverage(updated, data) };
  return updated;
};
const setFetchingVenuePresets = (state, data) => {
  let updated = {
    ...state,
    referral: {
      ...state.referral,
      fetchingVenuePresets: data,
    },
  };
  return updated;
};

const setTaxRegions = (state, data) => {
  const updated = { ...state };
  updated.venue.hasRegionalTax = data.hasRegionalTax;
  updated.venue.taxRegions = data.taxRegions;
  if (data.hasRegionalTax) {
    updated.venue.isFederalEntity = false;
    updated.venue.isKentuckyEntity = false;
  }
  return updated;
};

const setSelectedVenueCode = (state, data) => {
  const updated = { ...state };
  const initial = initialFormValues();
  updated.venue.venueCode = data || initial.venue.venueCode;
  return updated;
};

const updateVenueCodeValidity = (state, data) => {
  const updated = { ...state };
  updated.venue.venueCodeChecked = true;
  updated.venue.validVenueCode = data;
  return updated;
};

const resetVenueCodeValidation = (state) => {
  const updated = { ...state };
  const initial = initialFormValues();
  updated.venue.venueCodeChecked = initial.venue.venueCodeChecked;
  updated.venue.validVenueCode = initial.venue.validVenueCode;
  return updated;
};

const parseAddress = (data) => {
  const addressKeys = ["address1", "city", "state", "zip", "country"];

  const addressComponents = {};
  let address = "";
  addressKeys.forEach((key) => {
    addressComponents[key] = data[key];
    address += `${data[key]}, `;
  });
  addressComponents.companyName = data.companyName;
  address = address.slice(0, -2);

  return { address, addressComponents };
};

const selectPlaceByVenueCode = (state, data) => {
  const updated = { ...state };
  const addressParts = parseAddress(data.venue);
  const { address } = addressParts;
  const { addressComponents } = addressParts;
  updated.venue.selectedPlaceId = data.places.length
    ? data.places[0].placeId
    : "";
  updated.venue.selectedPlaceAddress = address;
  updated.venue.selectedPlaceAddressComponents = addressComponents;
  updated.venue.venueCodeChecked = true;
  updated.venue.validVenueCode = true;
  updated.venue.producer = {
    producerName: data.facility.producerName,
    producerLogoUrl: data.facility.producerLogo,
  };
  updated.venue.eventTypes = data.eventTypes;
  updated.venue.facilityLogoUrl = data.facility.facilityLogo;
  return updated;
};

const resetSelectedPlace = (state) => {
  const updated = { ...state };
  const initial = allInitialState();
  updated.venue = initial.venue;
  updated.venue.enabled = true;
  updated.venue.venueCodeChecked = true;
  return updated;
};

const setUtcOffset = (state, data) => {
  const updated = { ...state };
  updated.venue.selectedPlaceUtcOffset = data;
  return updated;
};

const clearSelectPlace = (state) => {
  const updated = { ...state };
  const initial = allInitialState();
  updated.venue.selectedPlaceId = initial.venue.selectedPlaceId;
  updated.venue.selectedPlaceAddress = initial.venue.selectedPlaceAddress;
  updated.venue.selectedPlaceAddressComponents =
    initial.venue.selectedPlaceAddressComponents;
  updated.venue.selectedPlaceUtcOffset = initial.venue.selectedPlaceUtcOffset;
  updated.venue.validVenue = initial.venue.validVenue;
  updated.venue.isBlocked = initial.venue.isBlocked;
  updated.venue.genericServerError = initial.venue.genericServerError;
  updated.venue.validVenueAddressAndState =
    initial.venue.validVenueAddressAndState;
  updated.venue.manualAddressComponents = initial.venue.manualAddressComponents;
  updated.venue.producer = initial.venue.producer;
  updated.venue.eventTypes = initial.venue.eventTypes;
  return updated;
};

const resetSpecialTaxFormFlags = (state) => {
  const updated = { ...state };
  updated.venue.isFederalEntity = true;
  updated.venue.isKentuckyEntity = true;
  return updated;
};

const preserveHasRegionalTax = (state, data) => {
  const updated = { ...state };
  updated.venue.hasRegionalTax = data;
  return updated;
};

const updateBlockedVenue = (state, data) => {
  const updated = { ...state };
  updated.venue.isBlocked = data;
  return updated;
};

const updateGenericServerError = (state, data) => {
  const updated = { ...state };
  updated.venue.genericServerError = data;
  return updated;
};

const fetchReferralFacility = (state) => {
  const updated = {
    ...state,
    referral: {
      ...state.referral,
      fetchingReferralFacility: true,
    },
  };

  return updated;
};

const fetchReferralProducer = (state) => ({
  ...state,
  referral: {
    ...state.referral,
    fetchingProducerCode: true,
  },
});

const setReferralFacility = (state, data) => {
  const updated = { ...state };
  updated.referral.facilityName = data.facilityName;
  updated.referral.facilityCode = data.code;
  updated.referral.facilityLogoUrl = data.logoUrl;
  updated.referral.fetchingReferralFacility = false;

  return updated;
};

const setReferralProducer = (state, data) => ({
  ...state,
  referral: {
    ...state.referral,
    producerCode: data.commissionId,
    subProducerCode: data.subProducerCode,
    producerLogoUrl: data.logoUrl,
    fetchingProducerCode: false,
  },
});

const setReferralVenue = (state, data) => ({
  ...state,
  referral: {
    ...state.referral,
    ...data,
  },
});

const clearVenueReferral = (state) => {
  const updated = {
    ...state,
    referral: {
      ...state.referral,
      venueCode: "",
      facilityCode: "",
      facilityLogoUrl: null,
    },
    venue: {
      ...state.venue,
      facilityLogoUrl: null,
    },
  };

  return updated;
};

const updateCertHolders = (state, data) => {
  const updated = { ...state };
  // Reference positions for additional insure address information
  // in the Certificate of Insurance document
  const additionalCertHolders = data.filter((certHolder) => {
    return (
      !isEmpty(certHolder) && additionalInsuredTypes.includes(certHolder.type)
    );
  });
  if (additionalCertHolders.length) {
    // sort additional cert holders to show them in correct order on summary page
    // venue_address, facility, venue
    additionalCertHolders.sort((a, b) => {
      return (
        additionalInsuredTypes.indexOf(a.type) -
        additionalInsuredTypes.indexOf(b.type)
      );
    });
  }
  updated.summary.additionalCertHolders = additionalCertHolders;
  return updated;
};

const updateIsCannabisPrevented = (state, isCannabisPrevented) => {
  const updated = { ...state };
  updated.venue.isCannabisPrevented = isCannabisPrevented;
  return updated;
};

const setValidVenueAddressAndState = (state, manualAddress) => {
  const updated = { ...state };
  const address = manualAddress || updated.venue.selectedPlaceAddressComponents;
  let isValidState = false;

  updated.usStates.some((e) => {
    if (e.code === address.state) {
      isValidState = true;
    }
    return e.code === address.state;
  });

  updated.venue.validVenueAddressAndState = !!(
    // Some Google search addresses may not have a venue name
    // but it's required for manual input address
    (
      (manualAddress ? address.companyName : true) &&
      address.address1 &&
      address.city &&
      address.state &&
      address.country &&
      address.zip &&
      isValidState
    )
  );

  return updated;
};

const updateManualAddress = (state, addressInput) => {
  const updated = { ...state };
  // retains facility referral address
  updated.summary.additionalCertHolders = [
    updated.summary.additionalCertHolders.shift(),
  ].filter((a) => a);

  if (updated.venue.validVenueAddressAndState) {
    const address = {
      ...updated.venue.manualAddressComponents,
      ...addressInput,
    };
    updated.venue.manualAddressComponents = address;
    updated.summary.additionalCertHolders.push(
      `${address.companyName ? address.companyName : ""}\n${
        address.address1
      }\n${address.city}, ${address.state} ${address.zip}`
    );
  }
  return updated;
};

const updateManualAddressCity = (state, data) => {
  const updated = { ...state };
  updated.venue.manualAddressComponents.city = data;
  return updated;
};

const venueCodeReferralOverride = (state, data) => {
  const updated = { ...state };
  if (updated.referral.venueCode) {
    updated.venue.venueCodeReferralOverride = data;
  }
  return updated;
};

const selectEventType = (state, eventType) => ({
  ...state,
  eventType: {
    ...state.eventType,
    selection: eventType,
    isValidating: true,
  },
});

const fetchEventTypeValidationSuccess = (state) => ({
  ...state,
  eventType: {
    ...state.eventType,
    isValid: true,
    isValidating: false,
    error: null,
  },
});

const fetchEventTypeValidationError = (state, error) => ({
  ...state,
  eventType: {
    ...state.eventType,
    isValidating: false,
    isValid: false,
    error,
  },
});

const setTheme = (state, theme) => ({
  ...state,
  theme,
});

const setBoilerplateReferral = (state, payload) => ({
  ...state,
  referral: {
    ...state.referral,
    boilerplate: payload,
  },
});

const completedOrder = (state) => {
  return {
    ...state,
    referral: {
      ...state.referral,
      boilerplate: null,
    },
    formUniqId: uniqid(),
  };
};

const setIsKentuckyCity = (state, { isKentuckyCity }) => ({
  ...state,
  venue: {
    ...state.venue,
    isKentuckyCity,
  },
});

const setKentuckyCity = (state, { kentuckyCity }) => ({
  ...state,
  venue: {
    ...state.venue,
    kentuckyCity,
  },
});

const setHasLookedUpTax = (state, { hasLookedUpTax }) => ({
  ...state,
  venue: {
    ...state.venue,
    hasLookedUpTax,
  },
});

// The reducer. Notice that all actions are handled by helpers to keep this function short.
export default (state = allInitialState(), action) => {
  const isForm = action && action.meta && action.meta.form === formName;
  if (action.type === EDIT_FORM)
    return {
      ...editForm(state, action.form),
      ...handleSummaryOpen(state),
    };
  if (action.type === BACK_FORM) return { ...backForm(state, action.form) };
  if (action.type === CONFIRM_FORM)
    return {
      ...confirmForm(state, action.form),
      ...handleSummaryOpen(state),
    };
  if (action.type === SELECT_PLACE)
    return {
      ...selectPlace(
        state,
        action.placeId,
        action.address,
        action.addressComponents,
        action.utcOffset,
        action.knownVenueData
      ),
    };
  if (isForm && action.type === UPDATE_SYNC_ERRORS)
    return { ...checkFormValidity(state, action.payload.syncErrors) };
  if (isForm && action.type === CHANGE)
    return { ...handleFormChange(state, action.meta.field, action.payload) };
  if (action.type === FETCH_QUOTE) {
    return { ...fetchQuote(state) };
  }
  if (action.type === FETCH_QUOTE_SUCCESS) {
    return { ...fetchQuoteSuccess(state, action.payload) };
  }
  if (action.type === FETCH_QUOTE_ERROR) {
    return { ...fetchQuoteError(state, action.payload) };
  }
  if (action.type === START_LOADING) {
    return { ...startLoading(state, action.form) };
  }
  if (action.type === END_LOADING) {
    return { ...endLoading(state, action.form) };
  }
  if (action.type === CREDITCARD_TOKEN_RESPONSE) {
    return { ...handleCreditCardTokenResponse(state, action) };
  }
  if (action.type === SUMMARY_TOGGLE) {
    return { ...toggleSummaryInfo(state) };
  }
  if (action.type === SUMMARY_MOBILE_TOGGLE) {
    return { ...toggleSummaryMobile(state, action.show) };
  }
  if (action.type === SUMMARY_HEIGHT) {
    return { ...summaryHeight(state, action.sHeight) };
  }
  if (action.type === SCREEN_SIZE) {
    return { ...mobileDisplayState(state, action.screenWidth) };
  }
  if (action.type === API_ERROR) {
    return { ...errorResponse(state, action.error, action.form) };
  }
  if (action.type === UPDATE_BLOCKED_VENUE) {
    return { ...updateBlockedVenue(state, action.payload) };
  }
  if (action.type === UPDATE_GENERIC_SERVER_ERROR) {
    return { ...updateGenericServerError(state, action.payload) };
  }
  if (action.type === SET_EVENT_TYPES) {
    return { ...setEventTypes(state, action.payload) };
  }
  if (action.type === SET_UNDERWRITING_QUESTIONS) {
    return { ...setUnderwritingQuestions(state, action.payload) };
  }
  if (action.type === SET_US_STATES) {
    return { ...setUSStates(state, action.payload) };
  }
  if (action.type === SET_AUTH_USER) {
    return { ...setAuthUser(state, action.payload) };
  }
  if (action.type === FETCH_US_STATES_FAILURE) {
    return { ...state, appDataError: true };
  }
  if (action.type === FETCH_EVENT_TYPES_FAILURE) {
    return { ...state, appDataError: true };
  }
  if (action.type === FETCH_UNDERWRITING_QUESTIONS_FAILURE) {
    return { ...state, appDataError: true };
  }
  if (action.type === SET_VENUE_PRESETS) {
    return { ...setVenuePresets(state, action.payload) };
  }
  if (action.type === SET_FETCHING_VENUE_PRESETS) {
    return { ...setFetchingVenuePresets(state, action.payload) };
  }
  if (action.type === DEFAULT_ADDITIONAL_COVERAGES_STATUS) {
    return { ...defaultAdditionalCoverageStatus(state) };
  }
  if (action.type === DEFAULT_BASIC_COVERAGE_OPTIONS) {
    return { ...defaultBasicCoverageOptions(state) };
  }
  if (action.type === SET_TAX_REGIONS) {
    return { ...setTaxRegions(state, action.payload) };
  }
  if (action.type === SET_SELECTED_VENUE_CODE) {
    return { ...setSelectedVenueCode(state, action.payload) };
  }
  if (action.type === UPDATE_VENUE_CODE_VALIDITY) {
    return { ...updateVenueCodeValidity(state, action.payload) };
  }
  if (action.type === RESET_VENUE_CODE_VALIDATION) {
    return { ...resetVenueCodeValidation(state) };
  }
  if (action.type === SELECT_PLACE_BY_VENUE_CODE) {
    return { ...selectPlaceByVenueCode(state, action.payload) };
  }
  if (action.type === RESET_SELECTED_PLACE) {
    return { ...resetSelectedPlace(state) };
  }
  if (action.type === CLEAR_SELECT_PLACE) {
    return { ...clearSelectPlace(state) };
  }
  if (action.type === RESET_SPECIAL_TAX_FORM_FLAGS) {
    return { ...resetSpecialTaxFormFlags(state) };
  }
  if (action.type === SET_UTC_OFFSET) {
    return { ...setUtcOffset(state, action.payload) };
  }
  if (action.type === PRESERVE_HAS_REGIONAL_TAX) {
    return { ...preserveHasRegionalTax(state, action.payload) };
  }
  if (action.type === HANDLE_LOCATION_SEARCH) {
    return { ...handleLocationSearch(state, action.payload) };
  }
  if (action.type === FETCH_REFERRAL_FACILITY) {
    return { ...fetchReferralFacility(state) };
  }
  if (action.type === FETCH_REFERRAL_PRODUCER) {
    return { ...fetchReferralProducer(state) };
  }
  if (action.type === SET_REFERRAL_FACILITY) {
    return { ...setReferralFacility(state, action.payload) };
  }
  if (action.type === SET_REFERRAL_PRODUCER) {
    return { ...setReferralProducer(state, action.payload) };
  }
  if (action.type === SET_REFERRAL_VENUE) {
    return { ...setReferralVenue(state, action.payload) };
  }
  if (action.type === CLEAR_VENUE_REFERRAL) {
    return { ...clearVenueReferral(state) };
  }
  if (action.type === UPDATE_CERT_HOLDERS) {
    return { ...updateCertHolders(state, action.payload) };
  }
  if (action.type === SET_VALID_VENUE_ADDRESS_AND_STATE) {
    return { ...setValidVenueAddressAndState(state, action.payload) };
  }
  if (action.type === UPDATE_MANUAL_ADDRESS) {
    return { ...updateManualAddress(state, action.payload) };
  }
  if (action.type === UPDATE_MANUAL_ADDRESS_CITY) {
    return { ...updateManualAddressCity(state, action.payload) };
  }
  if (action.type === VENUE_CODE_REFERRAL_OVERRIDE) {
    return { ...venueCodeReferralOverride(state, action.payload) };
  }
  if (action.type === SELECT_EVENT_TYPE) {
    return selectEventType(state, action.payload);
  }
  if (
    action.type === RESET_APPLICATION_FORM ||
    action.type === ROUTE_GUARD_BLOCKED ||
    action.type === PAGE_NOT_FOUND
  ) {
    return { ...state, ...ephemeralInitialState() };
  }
  if (action.type === FETCH_EVENT_TYPE_VALIDATION_SUCCESS) {
    return fetchEventTypeValidationSuccess(state);
  }
  if (action.type === FETCH_EVENT_TYPE_VALIDATION_ERROR) {
    return fetchEventTypeValidationError(state, action.payload);
  }
  if (action.type === SET_THEME) {
    return setTheme(state, action.payload);
  }

  if (action.type === FETCH_INSURANCE_COMPANIES) {
    return {
      ...state,
      insuranceCompaniesLoading: true,
    };
  }

  if (action.type === FETCH_INSURANCE_COMPANIES_SUCCESS) {
    return {
      ...state,
      insuranceCompanies: action.payload,
      insuranceCompaniesLoading: false,
    };
  }

  if (action.type === FETCH_INSURANCE_COMPANIES_ERROR) {
    return { ...state, insuranceCompaniesLoading: false };
  }

  if (action.type === SET_BOILERPLATE_REFERRAL) {
    return setBoilerplateReferral(state, action.payload);
  }

  if (action.type === COMPLETED_ORDER) {
    return completedOrder(state);
  }

  if (action.type === SET_IS_KENTUCKY_CITY) {
    return setIsKentuckyCity(state, action.payload);
  }

  if (action.type === SET_KENTUCKY_CITY) {
    return setKentuckyCity(state, action.payload);
  }

  if (action.type === SET_HAS_LOOKED_UP_TAX) {
    return setHasLookedUpTax(state, action.payload);
  }

  if (action.type === UPDATE_IS_CANNABIS_PREVENTED) {
    return updateIsCannabisPrevented(state, action.payload);
  }

  if (action.type === FETCHING_TAX_INFO) {
    return { ...state, fetchingTaxInfo: true };
  }
  if (
    action.type === FETCHING_TAX_INFO_SUCCESS ||
    action.type === FETCHING_TAX_INFO_ERROR
  ) {
    return { ...state, fetchingTaxInfo: false };
  }

  if (action.type === SET_NEED_OPTIONAL_VENUE) {
    return {
      ...state,
      venue: {
        ...state.venue,
        needOptionalVenue: action.payload.needOptionalVenue,
      },
    };
  }

  return state;
};
