import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import {
  underwritingQuestionPropType,
  DATA_TYPE_BOOLEAN,
  DATA_TYPE_TEXT,
} from "src/Models/UnderwritingQuestionModel";
import YesNoQuestionField from "./YesNoQuestionField/YesNoQuestionField";
import SelectQuestionField from "./SelectQuestionField";
import { selectionRequired } from "@gbli-events/common/src/Helpers/validators";

const propTypes = {
  name: PropTypes.string.isRequired,
  underwritingQuestion: underwritingQuestionPropType.isRequired,
  shouldRender: PropTypes.bool.isRequired,
};

const UnderwritingQuestionField = ({
  name,
  underwritingQuestion: { description, type, options, placeholder },
  shouldRender,
}) => {
  if (!shouldRender) return null;

  if (type === DATA_TYPE_BOOLEAN)
    return (
      <Field
        component={YesNoQuestionField}
        validate={selectionRequired}
        name={name}
        description={description}
      />
    );

  if (type === DATA_TYPE_TEXT && options)
    return (
      <Field
        component={SelectQuestionField}
        name={name}
        description={description}
        options={options}
        placeholder={placeholder}
      />
    );

  return null;
};

UnderwritingQuestionField.propTypes = propTypes;

export default UnderwritingQuestionField;
