import preval from "preval.macro";

// Name of the redux form main form
const formName = "application";
const contactUsFormName = "contactUs";
const cancelFormName = "cancelApplication";
const samplePolicyFormName = "samplePolicy";
const saveQuoteFormName = "saveQuote";
const saveAdditionalInsuredFormName = "saveAdditionalInsured";

// The time this app was built. Can be used for cache-busting for example.
const buildTime = preval`module.exports = Date.now()`;

// The API URL formatted to match the local address when in dev
// used to avoid issues accessing hosts from the network
// e.g. if localhost, but testing from a phone using local IP such as 192.168.1.1, assume the API is also the same IP
const regex = /(localhost|127.0.0.1)/;
const url = new URL(process.env.REACT_APP_GBLIEVENTS_API_LOCATION);
if (regex.test(url.hostname)) {
  url.hostname = window.location.hostname;
}
const apiUrl = url.href;

// Query params code
const SOURCE_CODE_PARAM = "agent";
const VENUE_CODE_PARAM = "prop";
const FACILITY_CODE_PARAM = "org";
const PRODUCER_CODE_PARAM = "pro";
const BOILERPLATE_UUID_PARAM = "uuid";
const BOILERPLATE_ASSIGNMENT_ID_PARAM = "aid";
const BOILERPLATE_TASK_ID_PARAM = "tid";
const BOILERPLATE_SIGNATURE_PARAM = "signature";

// Thank you page download timeout
const DOCUMENT_DOWNLOAD_WINDOW = 7200; // seconds, or 2 hours

// Vendor references
const PERFORMER = "Performer";
const FOOD_VENDOR = "Food Vendor";
const GOODS_VENDOR = "Goods Vendor";
const EXHIBITOR = "Exhibitor";

const US_TERRITORIES = ["PR", "VI"];

// Url Paths
const FORM_URL = "/:theme?/";
const FORM_EMBED_URL = "/:theme?/embed";

const VENUE_SELECTION_URL = "";
const EVENT_SELECTION_URL = "event-selection";
const UNDERWRITING_QUESTIONS_URL = "underwriting-questions";
const EVENT_INFORMATION_URL = "event-information";
const LIMITS_URL = "limits";
const INSURED_INFORMATION_URL = "insured-information";
const INSURANCE_CONTACT_URL = "insurance-contact";
const ADDITIONAL_INSURED_URL = "additional-insured";
const COVERAGE_SUMMARY_URL = "coverage-summary";
const PAYMENT_URL = "payment";
const THANK_YOU_URL = "thank-you";
const QUOTE_URL = "quote";
const SAMPLE_POLICY_URL = "sample-policy";

const PERMISSION_MANAGE_SETTINGS = "Manage Settings";

export {
  formName,
  cancelFormName,
  samplePolicyFormName,
  saveQuoteFormName,
  contactUsFormName,
  saveAdditionalInsuredFormName,
  buildTime,
  apiUrl,
  SOURCE_CODE_PARAM,
  VENUE_CODE_PARAM,
  FACILITY_CODE_PARAM,
  PRODUCER_CODE_PARAM,
  BOILERPLATE_UUID_PARAM,
  BOILERPLATE_ASSIGNMENT_ID_PARAM,
  BOILERPLATE_TASK_ID_PARAM,
  BOILERPLATE_SIGNATURE_PARAM,
  DOCUMENT_DOWNLOAD_WINDOW,
  PERFORMER,
  FOOD_VENDOR,
  GOODS_VENDOR,
  EXHIBITOR,
  US_TERRITORIES,
  FORM_URL,
  FORM_EMBED_URL,
  EVENT_SELECTION_URL,
  VENUE_SELECTION_URL,
  UNDERWRITING_QUESTIONS_URL,
  EVENT_INFORMATION_URL,
  LIMITS_URL,
  INSURED_INFORMATION_URL,
  INSURANCE_CONTACT_URL,
  ADDITIONAL_INSURED_URL,
  COVERAGE_SUMMARY_URL,
  PAYMENT_URL,
  THANK_YOU_URL,
  QUOTE_URL,
  SAMPLE_POLICY_URL,
  PERMISSION_MANAGE_SETTINGS,
};
