import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { change, formValueSelector, FieldArray } from "redux-form";
import { formName } from "src/Constants/constants";
import {
  editForm,
  confirmForm,
  VENUE_FORM,
  venuePresets,
  clearVenueReferral,
  clearVenueSearchForm,
  shouldLookupTax,
  clearRegionsList,
  updateVenueInputSelect,
  clearSelectPlace,
  clearSpecialTaxForms,
  getCoordsForUtc,
  setUtcOffset,
  setValidVenueAddressAndState,
  defaultBasicCoverageOptions,
  defaultAdditionalCoverageStatus,
  defaultAdditionalCoveragesForm,
  updateManualAddress,
  updateManualAddressCity,
  startLoading,
  GET_ADDITIONAL_CERT_HOLDERS,
  updateCertHolders,
} from "src/Actions/actions";
import {
  setIsKentuckyCity,
  setHasLookedUpTax,
  setKentuckyCity,
} from "src/Actions/venue";
import Debounce from "src/Helpers/Debounce";
import initialFormValues from "src/Constants/initialFormValues";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import SearchByAddress from "src/Components/Venue/SearchByAddress";
import venuePropType from "src/Constants/venueTypes";
import manualAddressPropType from "src/Constants/manualAddressTypes";
import ThemeColor from "@gbli-events/common/src/Components/Themed/ThemeColor";
import OptionalVenues from "src/Components/Combined/OptionalVenuesCombined";
import {
  getBlockedStateCodes,
  getIsKnownVenue,
  getVenueStatesMatch,
  isMainVenueValid,
  isValidState,
} from "src/Selectors/Venue";
import { GBLI_PHONE_EVENTS_TITLE } from "@gbli-events/common/src/Constants/shared";
import Alert from "@gbli-events/common/src/Components/Themed/Alert";
import SpecialTaxFields from "src/Components/Venue/SpecialTaxFields";
import FormStepNavigation from "src/Components/FormStepNavigation";
import Map from "../Venue/Map";

const debounce = new Debounce({ period: 500 });

const formValues = formValueSelector(formName);

const mapStateToProps = (state) => {
  const { usStates } = state.formPage;

  return {
    enabled: state.formPage.venue.enabled,
    confirmed: state.formPage.venue.confirmed,
    venue:
      state.formPage.venue.selectedPlaceAddressComponents.companyName || "",
    hasInput: !!formValues(state, "venue"),
    placeId: state.formPage.venue.selectedPlaceId || "",
    address: state.formPage.venue.selectedPlaceAddress,
    addressComponents: state.formPage.venue.selectedPlaceAddressComponents,
    mobile: state.formPage.summary.mobileDisplay,
    venueAddress1: state.formPage.venue.selectedPlaceAddressComponents.address1,
    venueCity: state.formPage.venue.selectedPlaceAddressComponents.city,
    venueState: state.formPage.venue.selectedPlaceAddressComponents.state || "",
    venueCountry: state.formPage.venue.selectedPlaceAddressComponents.country,
    venueZip: state.formPage.venue.selectedPlaceAddressComponents.zip,
    venueAddressType:
      state.formPage.venue.selectedPlaceAddressComponents.addressType,
    usStates,
    hasRegionalTax: state.formPage.venue.hasRegionalTax,
    taxRegions: state.formPage.venue.taxRegions,
    isKentuckyEntity: state.formPage.venue.isKentuckyEntity,
    venueMunicipalityCode: formValues(state, "venueMunicipalityCode"),
    venueSelectInputOptions: state.formPage.venueSelectInputOptions,
    venueSearchTypeRadio: formValues(state, "venueSearchTypeRadio"),
    fetchingVenuePresets: state.formPage.referral.fetchingVenuePresets,
    venueName:
      state.formPage.venue.selectedPlaceAddressComponents.companyName || "",
    venueCodeChecked: state.formPage.venue.venueCodeChecked || false,
    validVenueCode: state.formPage.venue.validVenueCode || false,
    manualVenueAddress: {
      companyName: formValues(state, "manualVenueName"),
      address1: formValues(state, "manualVenueAddress1"),
      city: formValues(state, "manualVenueCity"),
      state: formValues(state, "manualVenueState"),
      zip: formValues(state, "manualVenueZip"),
      country: formValues(state, "manualVenueCountry"),
    },
    validManualAddress:
      state.formPage.venue.validManualAddress &&
      (state.formPage.venue.hasRegionalTax
        ? !!formValues(state, "federalEntity") &&
          !!formValues(state, "kentuckyStateEntity")
        : true),
    federalEntity: formValues(state, "federalEntity"),
    kentuckyStateEntity: formValues(state, "kentuckyStateEntity"),
    isBlocked: state.formPage.venue.isBlocked,
    genericServerError: state.formPage.venue.genericServerError,
    isValidVenueAddress: isMainVenueValid(state),
    isValidVenueState: isValidState(
      state.formPage.venue.selectedPlaceAddressComponents.state,
      state
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  edit: () => dispatch(editForm(VENUE_FORM)),
  confirm: () => {
    return dispatch(confirmForm(VENUE_FORM));
  },
  venueInputSelect: (event) =>
    dispatch(updateVenueInputSelect(event.target.value)),
  onSelect: (
    _,
    placeId,
    address,
    addressComponents,
    utcOffset,
    setIsKentuckyCity,
    setHasLookedUpTax,
    setVenueSearchReadOnly
  ) => {
    dispatch(startLoading(GET_ADDITIONAL_CERT_HOLDERS));
    dispatch(
      venuePresets(
        placeId,
        address,
        addressComponents,
        utcOffset,
        setIsKentuckyCity,
        setHasLookedUpTax,
        setVenueSearchReadOnly
      )
    );
  },
  clearForm: (setVenueSearchReadOnly) => {
    dispatch(clearVenueSearchForm());
    dispatch(clearSpecialTaxForms());
    dispatch(clearRegionsList());
    dispatch(clearSelectPlace());
    dispatch(defaultBasicCoverageOptions());
    dispatch(defaultAdditionalCoverageStatus());
    dispatch(defaultAdditionalCoveragesForm());
    dispatch(
      change(
        formName,
        "generalLiabilityCoverage",
        initialFormValues().generalLiabilityCoverage
      )
    );
    setVenueSearchReadOnly(false);
  },
  clearVenueReferral: () => {
    dispatch(clearVenueReferral());
  },
  updateCertHolders: () => {
    dispatch(updateCertHolders([]));
  },
  isValidState: (venueState, usStates) => {
    let isValidState = false;
    usStates.some((e) => {
      if (e.code === venueState && !e.blocked) {
        isValidState = true;
      }
      return e.code === venueState;
    });

    return isValidState;
  },
  lookupTax: (
    state,
    city,
    setIsKentuckyCity,
    setHasLookedUpTax,
    // Optionally clear dependent form parts as well
    clear = true
  ) => {
    debounce.do(() => {
      if (clear) {
        dispatch(clearRegionsList());
        dispatch(clearSpecialTaxForms());
      }
      if (state && city) {
        dispatch(
          shouldLookupTax(
            state,
            city,
            setIsKentuckyCity,
            setHasLookedUpTax,
            clear
          )
        );
      }
    });
  },
  lookupUtc: (zip) => {
    if (zip.length === 5) debounce.do(() => dispatch(getCoordsForUtc(zip)));
    else debounce.do(() => dispatch(setUtcOffset()));
  },
  setIsValidManualVenueAddress: (address) =>
    dispatch(setValidVenueAddressAndState(address)),
  setManualAddress: (address) => dispatch(updateManualAddress(address)),
  updateManualCity: (city) => dispatch(updateManualAddressCity(city)),
  setIsKentuckyCity: (isKentuckyCity) =>
    dispatch(setIsKentuckyCity(isKentuckyCity)),
  setHasLookedUpTax: (hasLookedUpTax) =>
    dispatch(setHasLookedUpTax(hasLookedUpTax)),
  setKentuckyCity: (kentuckyCity) => dispatch(setKentuckyCity(kentuckyCity)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    confirm: () => dispatchProps.confirm(stateProps.placeId),
    setIsValidManualVenueAddress: (input) => {
      dispatchProps.setIsValidManualVenueAddress({
        ...stateProps.manualVenueAddress,
        ...input,
      });
    },
    setManualAddress: (input) =>
      dispatchProps.setManualAddress({
        ...stateProps.manualVenueAddress,
        ...input,
      }),
  };
};

const Venue = ({
  addressComponents,
  onSelect,
  placeId,
  address,
  venueState,
  mobile,
  isValidVenueAddress,
  isValidVenueState,
  clearForm,
  clearVenueReferral,
  hasInput,
  hasRegionalTax,
  taxRegions,
  isKentuckyEntity,
  fetchingVenuePresets,
  venueName,
  manualVenueAddress,
  lookupTax,
  isBlocked,
  genericServerError,
  updateManualCity,
  setKentuckyCity,
  setIsKentuckyCity,
  setHasLookedUpTax,
  updateCertHolders,
}) => {
  const isKnownVenue = useSelector(getIsKnownVenue);
  const venueStatesMatch = useSelector(getVenueStatesMatch);
  const blockedStates = useSelector(getBlockedStateCodes);
  /* eslint-disable no-unused-vars */
  const [venueSearchReadOnly, setVenueSearchReadOnly] = useState(false);
  // Invokes tax lookup on mount
  // to allow revalidation on page reload
  const initialTaxLookup = () => {
    lookupTax(
      manualVenueAddress.state,
      manualVenueAddress.city,
      setIsKentuckyCity,
      setHasLookedUpTax,
      false
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initialTaxLookup, []);

  const getKnownVenueAddressLabel = () => {
    return [
      addressComponents.companyName,
      addressComponents.address1,
      addressComponents.city,
      addressComponents.state,
      addressComponents.zip,
      addressComponents.country,
    ]
      .filter((component) => !!component)
      .join(", ");
  };

  return (
    <>
      <div className="venue">
        <div className="container">
          <FormStepNavigation />
          {fetchingVenuePresets ? (
            <ThemeColor variant="primary">
              <Spinner
                className="venue__spinner"
                animation="border"
                role="status"
              />
            </ThemeColor>
          ) : (
            <>
              <div className="d-flex align-items-center flex-column venue__sub-heading-wrapper">
                <div className="page-heading mb-0">
                  <h2>
                    <ThemeColor variant="secondary">
                      {isKnownVenue
                        ? "Get coverage for your event"
                        : "Where do you wish to host your event?"}
                    </ThemeColor>
                  </h2>
                </div>
                <div className="venue__restrictions mt-2">
                  Coverage is not available for venues in the following states:
                  <br />
                  {blockedStates.join(", ")}
                </div>
              </div>
              <div className="venue__form">
                {isKnownVenue ? (
                  <>
                    <p className="text-center font-weight-bold label">
                      Confirm Event Location.
                    </p>
                    <p className="text-center label-md-20-px">
                      {getKnownVenueAddressLabel()}
                    </p>
                    <p className="text-center">
                      <button
                        className="p-0 undecorate-btn venue__clear-btn"
                        type="button"
                        onClick={() => {
                          clearForm(setVenueSearchReadOnly);
                          clearVenueReferral();
                          updateCertHolders();
                        }}
                      >
                        <ThemeColor
                          variant="secondary"
                          className="font-weight-bold"
                        >
                          ( Location not correct? Click here )
                        </ThemeColor>
                      </button>
                    </p>
                    {isBlocked && (
                      <Alert variant="error" className="mt-3">
                        <i className="far fa-exclamation-circle alert__icon" />
                        <div className="alert__text">
                          Unfortunately, we cannot provide a policy from our
                          online special event insurance program for the
                          location provided. For more information, please call{" "}
                          <span className="text-nowrap">
                            {GBLI_PHONE_EVENTS_TITLE}
                          </span>
                          .
                        </div>
                      </Alert>
                    )}
                  </>
                ) : (
                  <>
                    <SearchByAddress
                      label={
                        <p className="text-center font-weight-bold">
                          Choose the location of your event.
                        </p>
                      }
                      name="venue"
                      onSelect={onSelect}
                      address={address}
                      venueState={venueState}
                      mobile={mobile}
                      isValidAddress={isValidVenueAddress}
                      clearForm={clearForm}
                      hasInput={hasInput}
                      isValidState={isValidVenueState}
                      hasRegionalTax={hasRegionalTax}
                      taxRegions={taxRegions}
                      isKentuckyEntity={isKentuckyEntity}
                      setKentuckyCity={setKentuckyCity}
                      setIsKentuckyCity={setIsKentuckyCity}
                      setHasLookedUpTax={setHasLookedUpTax}
                      venueSearchReadOnly={!!placeId}
                      setVenueSearchReadOnly={() => {}}
                      isBlocked={isBlocked}
                      genericServerError={genericServerError}
                      updateManualCity={updateManualCity}
                      venueName={venueName}
                    />
                  </>
                )}
                {!isBlocked && (
                  <SpecialTaxFields
                    hasRegionalTax={hasRegionalTax}
                    taxRegions={taxRegions}
                    isKentuckyEntity={isKentuckyEntity}
                    setKentuckyCity={setKentuckyCity}
                  />
                )}
                {process.env.REACT_APP_SHOW_GOOGLE_MAP === "true" &&
                  placeId &&
                  address &&
                  !isBlocked &&
                  isValidVenueState &&
                  (isKnownVenue || isValidVenueAddress) && (
                    <Map
                      address={`${venueName && `${venueName}, `} ${address}`}
                      placeId={placeId}
                      google={window.google}
                    />
                  )}
                <FieldArray name="optionalVenues" component={OptionalVenues} />
                {!venueStatesMatch && (
                  <Alert variant="error" className="mt-3">
                    <i className="far fa-exclamation-circle alert__icon" />
                    <div className="alert__text">
                      The state of the second venue must be in the same state as
                      the above primary venue. If your second venue is in a
                      separate state, please purchase a separate policy for that
                      venue.
                    </div>
                  </Alert>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
Venue.propTypes = {
  addressComponents: PropTypes.any.isRequired,
  confirm: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeId: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  venueState: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
  isValidVenueAddress: PropTypes.bool.isRequired,
  isValidVenueState: PropTypes.bool.isRequired,
  clearForm: PropTypes.func.isRequired,
  clearVenueReferral: PropTypes.func.isRequired,
  hasInput: PropTypes.bool.isRequired,
  hasRegionalTax: PropTypes.bool.isRequired,
  taxRegions: PropTypes.arrayOf(venuePropType).isRequired,
  isKentuckyEntity: PropTypes.bool.isRequired,
  fetchingVenuePresets: PropTypes.bool.isRequired,
  venueName: PropTypes.string.isRequired,
  manualVenueAddress: manualAddressPropType.isRequired,
  lookupTax: PropTypes.func.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  genericServerError: PropTypes.bool.isRequired,
  updateManualCity: PropTypes.func.isRequired,
  setKentuckyCity: PropTypes.func.isRequired,
  setIsKentuckyCity: PropTypes.func.isRequired,
  setHasLookedUpTax: PropTypes.func.isRequired,
  updateCertHolders: PropTypes.func.isRequired,
};

const VenueContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Venue);

export default VenueContainer;
