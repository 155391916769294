import { useState } from "react";
import { useSelector } from "react-redux";
import { Navbar, Modal, Nav } from "react-bootstrap";
import { StyledHeader, StyledHeaderDiv } from "./StyledHeader";
import HeaderLogo from "./HeaderLogo/HeaderLogo";
import HeaderLink from "./HeaderLink/HeaderLink";
import { getTheme, getValidatingReferralCodes } from "src/Selectors/Shared";
import {
  getReferralQueryParams,
  getShowAgentsLink,
} from "src/Selectors/Shared";
import { getReferralLogo } from "src/Selectors/Shared";

const Header = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const theme = useSelector(getTheme);
  const themeNavItems = theme.components.Header?.navItems;
  const referralQueryParams = useSelector(getReferralQueryParams);
  const validatingReferralCodes = useSelector(getValidatingReferralCodes);
  const showAgentsLink = useSelector(getShowAgentsLink);
  const logo = useSelector(getReferralLogo);

  const navItems = (
    <Nav className="themed-header__navigation">
      {themeNavItems &&
        !validatingReferralCodes &&
        themeNavItems
          .filter((item) => !(item.label === "For Agents" && !showAgentsLink))
          .map(({ label, url, target }, index) => (
            <Nav.Item className="themed-header__navigation-item" key={index}>
              <HeaderLink
                href={url + referralQueryParams}
                target={target || "_self"}
                rel="noopener"
              >
                {label}
              </HeaderLink>
            </Nav.Item>
          ))}
    </Nav>
  );

  const hideMobileNav = () => setShowMobileNav(false);
  return (
    <>
      <StyledHeader className="themed-header">
        <div className="container">
          <Navbar className="py-2" expand="lg">
            <Navbar.Brand>
              <button
                className="undecorate-btn"
                onClick={() => {
                  window.location.assign(
                    theme.components.HeaderLogo.href + referralQueryParams
                  );
                  hideMobileNav();
                }}
              >
                {(logo && (
                  <HeaderLogo
                    className="customLogo"
                    src={logo.url}
                    alt={logo.alt}
                  />
                )) || (
                  <HeaderLogo
                    className="defaultLogo"
                    src={theme.components.Header.logoUrl}
                    alt="Logo"
                  />
                )}
              </button>
            </Navbar.Brand>
            <Navbar.Toggle
              className="border-0 p-0"
              onClick={() => {
                setShowMobileNav(!showMobileNav);
              }}
              aria-controls="responsive-navbar-nav"
            >
              {showMobileNav ? (
                <i className="fal fa-times" />
              ) : (
                <i className="fas fa-bars themed-header__bars"></i>
              )}
            </Navbar.Toggle>
            <div className="d-none d-lg-block ml-auto">{navItems}</div>
          </Navbar>
        </div>
      </StyledHeader>
      <Modal
        className="header__side-modal"
        backdropClassName="header__side-modal-backdrop"
        dialogClassName="header__dialog"
        show={showMobileNav}
        onHide={hideMobileNav}
      >
        <StyledHeaderDiv className="px-4">{navItems}</StyledHeaderDiv>
      </Modal>
    </>
  );
};

export default Header;
