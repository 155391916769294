import {
  fetchEventTypeValidationError,
  fetchEventTypeValidationSuccess,
} from "src/Actions/events";
import { formName } from "src/Constants/constants";
import { CHANGE } from "redux-form/lib/actionTypes";
import EventTypeService from "src/Helpers/EventTypeService";
import API from "src/Helpers/API";

const EventTypeValidationMiddleWare = (store) => (next) => (action) => {
  const cannabisOptionChange =
    action.type === CHANGE &&
    action.meta.form === formName &&
    action.meta.field === "cannabis";

  const selectEventType =
    action.type === CHANGE &&
    action.meta.form === formName &&
    action.meta.field === "eventType";

  if (selectEventType || cannabisOptionChange) {
    const result = next(action);
    const state = store.getState();
    const eventType =
      action.payload?.value || state.form.application.values.eventType?.value;
    const cannabis = state.form.application.values.cannabis || "0";

    if (eventType) {
      new EventTypeService(new API())
        .validateEventType({
          eventTypeId: eventType.identifier,
          cannabis,
        })
        .then(
          () => {
            store.dispatch(fetchEventTypeValidationSuccess());
          },
          (error) => {
            store.dispatch(fetchEventTypeValidationError(error.response.data));
          }
        );
    } else {
      store.dispatch(fetchEventTypeValidationSuccess());
    }

    return result;
  }

  return next(action);
};

export default EventTypeValidationMiddleWare;
