import deepMerge from "deepmerge";
import cloneDeep from "lodash/cloneDeep";
import RouteParser from "../Helpers/RouteParser";
import { venueSearchInputOptions } from "./venueSearchTypes";
import { emptyVenueForm } from "src/Models/VenueFormModel";
import { GLL_1 } from "@gbli-events/common/src/Constants/limits";
import uniqid from "uniqid";

// redux store state 'formPage' initial state. Also represents the shape of its state.
// Note that properties might be wiped, so there are two sets:
// stickyProperties that should not be wiped, and ephemeralProperties that can be wiped

const stickyProperties = {
  eventTypes: [],
  usStates: [],
  insuranceCompanies: [],
  underwritingQuestions: [],
  referral: {
    producerCode: null,
    producerLogoUrl: null,
    facilityLogoUrl: null,
    fetchingReferralFacility: false,
    fetchingVenuePresets: false,
    fetchingProducerCode: false,
    facilityName: "",
    sourceCode: "",
    venueCode: "",
    facilityCode: "",
    boilerplate: null,
  },
  isEmbed: window.location.pathname.includes("embed"),
  theme: null,
  formUniqId: uniqid(),
  authUser: [],
};

const insuredInfoInitialState = {
  insured: {
    enabled: false,
    confirmed: false,
    valid: false,
    insuredRenter: false,
    insuredRenterType: "Person",
    insuredCompanyType: "Individual",
  },
  insuranceContact: {
    enabled: false,
    confirmed: false,
    valid: false,
    contactSame: true,
    contactRenter: false,
  },
  paymentInfo: {
    enabled: false,
    confirmed: false,
    valid: false,
    isLoading: false,
    payeeSame: true,
    payeeRenter: false,
    token: "",
    cardType: "",
    lastFour: "",
    errorMessage: "",
  },
};
const ephemeralProperties = {
  insuranceCompaniesLoading: false,
  maintananceMode: false,
  appDataError: false,
  venueSelectInputOptions: venueSearchInputOptions,
  currentLocation: "",
  screenWidth: typeof window === "object" ? window.innerWidth : null,
  formPage: RouteParser.formParts.eventType,
  priceValues: {
    basicCoverageAmount: 0,
    liquor: 0,
    liquorPerDay: 0,
    damageToRentedProperty: 0,
    damageToRentedPropertyPerDay: 0,
    personalProperty: 0,
    cancellation: 0,
    subtotal: 0,
    federalSurcharge: 0,
    stateSurcharge: 0,
    tax: 0,
    total: 0,
  },
  eventType: {
    selection: null,
    isValidating: false,
    isValid: false,
    error: null,
  },
  formPartOne: {
    complete: false,
  },
  formPartTwo: {
    complete: false,
  },
  summary: {
    excludedCheck: false,
    consentCheck: false,
    height: 0,
    mobileShow: true,
    isLoading: false,
    errorResponse: false,
    additionalCertHolders: [],
    isGettingCertHolders: false,
    isOpen: true,
    mobileDisplay: false,
  },
  eventSelect: {
    valid: false,
    eventTypeSelect: null,
  },
  eventSelectSecondary: {
    valid: false,
    showAlert: false,
    lossClaims: null,
    security: null,
    promoter: null,
  },
  fetchingTaxInfo: false,
  venue: cloneDeep(emptyVenueForm),
  basicCoverage: {
    enabled: false,
    confirmed: false,
    valid: false,
    coverage: GLL_1,
    glLimit: "",
    options: [],
  },
  aboutEvent: {
    enabled: false,
    confirmed: false,
    valid: false,
  },
  additionalCoverages: [],
  ...insuredInfoInitialState,
  quote: {
    isFetching: false,
    quote: null,
    error: null,
  },
};

const clone = (o) => cloneDeep(o);

// The whole initial state. Use this for initializing state the very first time
const allInitialState = () =>
  deepMerge(clone(ephemeralProperties), clone(stickyProperties));
// Properties that can be preserved even when wiping formPage
const ephemeralInitialState = () => clone(ephemeralProperties);

export { allInitialState, ephemeralInitialState, insuredInfoInitialState };
