import React, { Fragment } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import SearchByAddress from "./SearchByAddress";
import { formName } from "src/Constants/constants";
import {
  chooseOptionalVenue,
  clearOptionalVenue,
} from "src/Actions/optionalVenues";
import { isValidAddress } from "@gbli-events/common/src/Helpers/AddressTypes";
import { isValidState, isUniqueOptionalVenue } from "src/Selectors/Venue";
import { FieldsProps } from "@gbli-events/common/src/Constants/ReduxFormPropTypes";
import { venueFormPropType } from "src/Models/VenueFormModel";

const formValues = formValueSelector(formName);

const mapStateToProps = (state) => ({
  optionalVenueFormValues: formValues(state, "optionalVenues"),
  isMobile: state.formPage.summary.mobileDisplay,
  isValidState: (venueState) => isValidState(venueState, state),
  isValidAddress,
  isUniqueOptionalVenue: (optionalVenue) =>
    isUniqueOptionalVenue(state, optionalVenue),
});

const mapDispatchToProps = (dispatch) => ({
  onSelect: (index, _, placeId, address, addressComponents, utcOffset) => {
    dispatch(
      chooseOptionalVenue(index, placeId, address, addressComponents, utcOffset)
    );
  },
  clearForm: (index) => {
    dispatch(clearOptionalVenue({ index }));
  },
});

const OptionalVenues = ({
  fields,
  optionalVenueFormValues,
  isMobile,
  isValidState,
  onSelect,
  clearForm,
  isValidAddress,
  isUniqueOptionalVenue,
}) => {
  return (
    <div className="optional-venues mt-4 mt-lg--40">
      {fields.map((venueFormItem, index) => {
        const venueForm = optionalVenueFormValues[index];

        return (
          <Fragment key={venueFormItem}>
            <p className="text-center optional-venues__heading mb--20">
              <span className="font-weight-bold">
                Do you need to add a second venue?
              </span>{" "}
              (Optional)
            </p>
            <SearchByAddress
              name={`${venueFormItem}.venueSearch`}
              onSelect={(_, placeId, address, addressComponents, utcOffset) => {
                onSelect(
                  index,
                  _,
                  placeId,
                  address,
                  addressComponents,
                  utcOffset
                );
              }}
              address={venueForm.selectedPlaceAddress}
              venueState={venueForm.selectedPlaceAddressComponents.state || ""}
              mobile={isMobile}
              isValidAddress={isValidAddress(
                venueForm.selectedPlaceAddressComponents.address1,
                venueForm.selectedPlaceAddressComponents.city,
                venueForm.selectedPlaceAddressComponents.state || "",
                venueForm.selectedPlaceAddressComponents.country,
                venueForm.selectedPlaceAddressComponents.zip,
                venueForm.selectedPlaceAddressComponents.addressType
              )}
              clearForm={() => clearForm(index)}
              hasInput={!!venueForm.venueSearch}
              isValidState={isValidState(
                venueForm.selectedPlaceAddressComponents.state
              )}
              hasRegionalTax={venueForm.hasRegionalTax}
              taxRegions={venueForm.taxRegions}
              isKentuckyEntity={venueForm.isKentuckyEntity}
              setKentuckyCity={() => {}}
              setIsKentuckyCity={() => {}}
              setHasLookedUpTax={() => {}}
              venueSearchReadOnly={!!venueForm.selectedPlaceId}
              setVenueSearchReadOnly={() => {}}
              isBlocked={venueForm.isBlocked}
              genericServerError={venueForm.genericServerError}
              updateManualCity={() => {}}
              venueName={
                venueForm.selectedPlaceAddressComponents.companyName || ""
              }
              alreadySelectedError={!isUniqueOptionalVenue(venueForm)}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

OptionalVenues.propTypes = {
  fields: FieldsProps,
  optionalVenueFormValues: PropTypes.arrayOf(venueFormPropType).isRequired,
  isMobile: PropTypes.bool.isRequired,
  isValidState: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  isValidAddress: PropTypes.func.isRequired,
  isUniqueOptionalVenue: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionalVenues);
