/**
 * Middleware used to set document title and favicon from theme
 */
import { SET_THEME } from "@gbli-events/common/src/Actions/themes";

export default (store) => (next) => (action) => {
  if (action.type === SET_THEME) {
    const theme = action.payload;
    document.title = theme.title;

    theme.favicons.forEach(({ rel, type, sizes, href }) => {
      let link = document.createElement("link");
      link.rel = rel;
      link.type = type;
      link.sizes = sizes;
      document.getElementsByTagName("head")[0].appendChild(link);
      link.href = href;
    });
  }

  return next(action);
};
