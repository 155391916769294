import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";
import { formName } from "../Constants/constants";
import {
  editForm,
  confirmForm,
  VENUE_FORM,
  venuePresets,
  clearVenueReferral,
  clearVenueSearchForm,
  shouldLookupTax,
  clearRegionsList,
  updateVenueInputSelect,
  clearSelectPlace,
  clearSpecialTaxForms,
  getCoordsForUtc,
  setUtcOffset,
  setValidVenueAddressAndState,
  defaultBasicCoverageOptions,
  defaultAdditionalCoverageStatus,
  defaultAdditionalCoveragesForm,
  updateManualAddress,
  updateManualAddressCity,
  startLoading,
  GET_ADDITIONAL_CERT_HOLDERS,
} from "../Actions/actions";
import {
  setIsKentuckyCity,
  setHasLookedUpTax,
  setKentuckyCity,
} from "../Actions/venue";
import Venue from "../Components/Venue/Venue";
import Debounce from "../Helpers/Debounce";
import initialFormValues from "../Constants/initialFormValues";
import {
  isVenuePageValid,
  isMainVenueValid,
  isValidState,
} from "src/Selectors/Venue";

const debounce = new Debounce({ period: 500 });

const formValues = formValueSelector(formName);

const mapStateToProps = (state) => {
  const { usStates } = state.formPage;

  return {
    enabled: state.formPage.venue.enabled,
    confirmed: state.formPage.venue.confirmed,
    venue:
      state.formPage.venue.selectedPlaceAddressComponents.companyName || "",
    hasInput: !!formValues(state, "venue"),
    placeId: state.formPage.venue.selectedPlaceId || "",
    address: state.formPage.venue.selectedPlaceAddress,
    addressComponents: state.formPage.venue.selectedPlaceAddressComponents,
    mobile: state.formPage.summary.mobileDisplay,
    venueAddress1: state.formPage.venue.selectedPlaceAddressComponents.address1,
    venueCity: state.formPage.venue.selectedPlaceAddressComponents.city,
    venueState: state.formPage.venue.selectedPlaceAddressComponents.state || "",
    venueCountry: state.formPage.venue.selectedPlaceAddressComponents.country,
    venueZip: state.formPage.venue.selectedPlaceAddressComponents.zip,
    venueAddressType:
      state.formPage.venue.selectedPlaceAddressComponents.addressType,
    usStates,
    hasRegionalTax: state.formPage.venue.hasRegionalTax,
    taxRegions: state.formPage.venue.taxRegions,
    isKentuckyEntity: state.formPage.venue.isKentuckyEntity,
    venueMunicipalityCode: formValues(state, "venueMunicipalityCode"),
    venueSelectInputOptions: state.formPage.venueSelectInputOptions,
    venueSearchTypeRadio: formValues(state, "venueSearchTypeRadio"),
    fetchingVenuePresets: state.formPage.referral.fetchingVenuePresets,
    venueName:
      state.formPage.venue.selectedPlaceAddressComponents.companyName || "",
    venueCodeChecked: state.formPage.venue.venueCodeChecked || false,
    validVenueCode: state.formPage.venue.validVenueCode || false,
    manualVenueAddress: {
      companyName: formValues(state, "manualVenueName"),
      address1: formValues(state, "manualVenueAddress1"),
      city: formValues(state, "manualVenueCity"),
      state: formValues(state, "manualVenueState"),
      zip: formValues(state, "manualVenueZip"),
      country: formValues(state, "manualVenueCountry"),
    },
    validManualAddress:
      state.formPage.venue.validManualAddress &&
      (state.formPage.venue.hasRegionalTax
        ? !!formValues(state, "federalEntity") &&
          !!formValues(state, "kentuckyStateEntity")
        : true),
    federalEntity: formValues(state, "federalEntity"),
    kentuckyStateEntity: formValues(state, "kentuckyStateEntity"),
    isBlocked: state.formPage.venue.isBlocked,
    genericServerError: state.formPage.venue.genericServerError,
    isVenuePageValid: isVenuePageValid(state),
    isValidVenueAddress: isMainVenueValid(state),
    isValidVenueState: isValidState(
      state.formPage.venue.selectedPlaceAddressComponents.state,
      state
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  edit: () => dispatch(editForm(VENUE_FORM)),
  confirm: () => {
    return dispatch(confirmForm(VENUE_FORM));
  },
  venueInputSelect: (event) =>
    dispatch(updateVenueInputSelect(event.target.value)),
  onSelect: (
    _,
    placeId,
    address,
    addressComponents,
    utcOffset,
    setIsKentuckyCity,
    setHasLookedUpTax,
    setVenueSearchReadOnly
  ) => {
    dispatch(startLoading(GET_ADDITIONAL_CERT_HOLDERS));
    dispatch(
      venuePresets(
        placeId,
        address,
        addressComponents,
        utcOffset,
        setIsKentuckyCity,
        setHasLookedUpTax,
        setVenueSearchReadOnly
      )
    );
  },
  clearForm: (setVenueSearchReadOnly) => {
    dispatch(clearVenueSearchForm());
    dispatch(clearSpecialTaxForms());
    dispatch(clearRegionsList());
    dispatch(clearSelectPlace());
    dispatch(defaultBasicCoverageOptions());
    dispatch(defaultAdditionalCoverageStatus());
    dispatch(defaultAdditionalCoveragesForm());
    dispatch(
      change(
        formName,
        "generalLiabilityCoverage",
        initialFormValues().generalLiabilityCoverage
      )
    );
    setVenueSearchReadOnly(false);
  },
  clearVenueReferral: () => {
    dispatch(clearVenueReferral());
  },
  lookupTax: (
    state,
    city,
    setIsKentuckyCity,
    setHasLookedUpTax,
    // Optionally clear dependent form parts as well
    clear = true
  ) => {
    debounce.do(() => {
      if (clear) {
        dispatch(clearRegionsList());
        dispatch(clearSpecialTaxForms());
      }
      if (state && city) {
        dispatch(
          shouldLookupTax(
            state,
            city,
            setIsKentuckyCity,
            setHasLookedUpTax,
            clear
          )
        );
      }
    });
  },
  lookupUtc: (zip) => {
    if (zip.length === 5) debounce.do(() => dispatch(getCoordsForUtc(zip)));
    else debounce.do(() => dispatch(setUtcOffset()));
  },
  setIsValidManualVenueAddress: (address) =>
    dispatch(setValidVenueAddressAndState(address)),
  setManualAddress: (address) => dispatch(updateManualAddress(address)),
  updateManualCity: (city) => dispatch(updateManualAddressCity(city)),
  setIsKentuckyCity: (isKentuckyCity) =>
    dispatch(setIsKentuckyCity(isKentuckyCity)),
  setHasLookedUpTax: (hasLookedUpTax) =>
    dispatch(setHasLookedUpTax(hasLookedUpTax)),
  setKentuckyCity: (kentuckyCity) => dispatch(setKentuckyCity(kentuckyCity)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    confirm: () => dispatchProps.confirm(stateProps.placeId),
    setIsValidManualVenueAddress: (input) => {
      dispatchProps.setIsValidManualVenueAddress({
        ...stateProps.manualVenueAddress,
        ...input,
      });
    },
    setManualAddress: (input) =>
      dispatchProps.setManualAddress({
        ...stateProps.manualVenueAddress,
        ...input,
      }),
  };
};

const VenueContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Venue);

export default VenueContainer;
