import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getFilteredFormStepsConfig } from "src/Selectors/Shared";
import { Link, useRouteMatch } from "react-router-dom";
import { getFormRelativePath } from "src/Helpers/URLParser";
import { useGetFormPath } from "src/Hooks/FormPaths";

const Styles = styled.div`
  a,
  a:hover {
    font-weight: 600;
  }

  .nav-back-button {
    font-size: 1rem;
    cursor: pointer;

    &__back {
      text-transform: uppercase;
      letter-spacing: 3.64px;
      margin-right: 1rem;
      font-family: ${({ theme }) => theme.typography.fonts.secondary};
    }

    &__steps {
      font-family: ${({ theme }) => theme.typography.fonts.primary};
    }

    i {
      margin-right: 0.25rem;
    }
  }
`;

const NavBackButton = () => {
  const formStepUrls = Object.keys(useSelector(getFilteredFormStepsConfig));

  const { path } = useRouteMatch();
  const relativePath = getFormRelativePath(path);
  const currentPathIndex = formStepUrls.indexOf(relativePath);
  const prevUrl = useGetFormPath(formStepUrls[currentPathIndex - 1]);

  const suffix = `${currentPathIndex + 1} of ${formStepUrls.length}`;
  const isFirstStep = currentPathIndex === 0;

  return (
    <Styles className="nav-back-button">
      {isFirstStep ? (
        <div className="font-weight-bold ml-3">
          <span className="nav-back-button__back">Step</span>
          <span className="nav-back-button__steps">{suffix}</span>
        </div>
      ) : (
        <Link to={prevUrl}>
          <i className="fas fa-chevron-left mr-2"></i>
          <span className="nav-back-button__back">Back</span>
          <span className="nav-back-button__steps">{suffix}</span>
        </Link>
      )}
    </Styles>
  );
};

export default NavBackButton;
