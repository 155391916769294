import PropTypes from "prop-types";

export const BY_SEARCH = "bySearch";
export const BY_VENUE_CODE = "byVenueCode";
export const BY_MANUAL_ADDRESS = "byManualAddress";

const venueSearchInputOptions = [
  {
    name: "Use a venue code",
    value: BY_VENUE_CODE,
  },
  {
    name: "Search for a venue",
    value: BY_SEARCH,
  },
  {
    name: "Enter my own venue information",
    value: BY_MANUAL_ADDRESS,
  },
];

const venueSearchPropType = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.string,
});

export { venueSearchInputOptions, venueSearchPropType };
