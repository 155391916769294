import React from "react";
import { Dropdown } from "react-bootstrap";
import { format } from "date-fns";
import {
  GBLI_PHONE_EVENTS,
  GBLI_EMAIL_EVENTS,
  GBLI_PHONE_EVENTS_TITLE,
} from "@gbli-events/common/src/Constants/shared";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className="trigger"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <i className="far fa-ellipsis-v"></i>
  </button>
));

const GBLIFooter = () => {
  const navItems = [
    {
      href: "https://gbli.com/terms/",
      label: "Terms & Conditions",
      className: "terms",
    },
    {
      href: "https://gbli.com/legal/",
      label: "Legal & Privacy Notices",
      className: "legal",
    },
    {
      href: "https://gbli.com/gbli/transparency-in-coverage/",
      label: "Transparency in Coverage",
      className: "transparency",
    },
    {
      href: "https://gbli.com/gbli/no-surprises-act-nsa/",
      label: "No Surprises Act (NSA)",
      className: "nsa",
    },
    {
      href: "https://gbli.com/gbli/eeo-statement/",
      label: "EEO",
      className: "eeo",
    },
    {
      href: "https://gbli.com/careers/",
      label: "Careers",
      className: "careers",
    },
    {
      href: "https://gbli.com/about-us/#contact_about",
      label: "Contact Us",
      className: "contact-us",
    },
  ];

  return (
    <footer className="footer">
      <div className="footer__container container">
        <nav>
          <ul className="footer__nav-list">
            {navItems.map(({ href, label, className }) => (
              <li className={`footer__nav-item ${className}`} key={href}>
                <a href={href} target="_blank" rel="noreferrer">
                  {label}
                </a>
              </li>
            ))}
            <li className="footer__nav-menu d-none d-md-inline">
              <Dropdown drop="up">
                <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                <Dropdown.Menu className="nav-menu-dropdown">
                  {navItems.map(({ href, label, className }) => (
                    <Dropdown.Item
                      key={href}
                      href={href}
                      target="_blank"
                      rel="noreferrer"
                      className={`nav-menu-dropdown__item ${className}`}
                    >
                      {label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </nav>
        <div className="footer__contact-us">
          <h4>
            <a
              href="https://gbli.com/about-us/#contact_about"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
          </h4>
          <p>
            <a href={`tel:${GBLI_PHONE_EVENTS}`}>{GBLI_PHONE_EVENTS_TITLE}</a>
          </p>
          <p>
            <a href={`mailto:${GBLI_EMAIL_EVENTS}`}>{GBLI_EMAIL_EVENTS}</a>
          </p>
        </div>
        <a
          className="footer__linkedin"
          href="https://www.linkedin.com/company/global-indemnity-group/"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-linkedin-in"></i>
        </a>
        <p className="footer__copyright">
          Copyright © {format(new Date(), "yyyy")} Global Indemnity Group. All
          rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default GBLIFooter;
