import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import Modal from "react-bootstrap/Modal";
import store from "src/store";
import priceChangeObserver from "src/Helpers/PriceChangeObserver";
import EventSummaryTable from "../EventSummaryTable";
import PriceSummaryTable from "../PriceSummaryTable";
import InsuredSummaryTable from "../CoverageSummary/InsuredSummaryTable";
import InsuranceContactSummaryTable from "../CoverageSummary/InsuranceContactSummaryTable";
import PriceSummaryFooter from "../PriceSummaryFooter/PriceSummaryFooter";
import TotalQuote from "../TotalQuote";
import { getIsInsuranceContactPageValid } from "src/Selectors/InsuranceContact";
import { getIsInsuredInformationPageValid } from "src/Selectors/InsuredInformation";
import StyledQuoteSummaryFloater from "./StyledQuoteSummaryFloater";

const propTypes = {
  disableStoreSubscription: PropTypes.bool,
};

const QuoteSummaryFloater = ({ disableStoreSubscription }) => {
  const [show, setShow] = useState(false);

  const isInsuranceContactPageValid = useSelector(
    getIsInsuranceContactPageValid
  );

  const isInsuredInformationPageValid = useSelector(
    getIsInsuredInformationPageValid
  );

  useEffect(
    () => (disableStoreSubscription ? undefined : priceChangeObserver(store)),
    [disableStoreSubscription]
  );

  return (
    <StyledQuoteSummaryFloater className="quote-summary-floater">
      <button
        className="quote-summary-floater__button"
        type="button"
        onClick={() => setShow(true)}
      >
        <i className="fas fa-chevron-up quote-summary-floater__arrow"></i>
        <p className="quote-summary-floater__btn-copy">
          <span>Quote Summary </span>
          <span className="quote-summary-floater__total d-none d-lg-inline-flex ml-4">
            <TotalQuote />
          </span>
        </p>
        <div className="quote-summary-floater__total d-lg-none">
          <TotalQuote />
        </div>
      </button>
      <Modal
        dialogClassName="quote-summary-floater__dialog"
        show={show}
        onHide={() => setShow(false)}
      >
        <StyledQuoteSummaryFloater>
          <button
            onClick={() => setShow(false)}
            className="quote-summary-floater__arrow-close"
            type="button"
          >
            <i className="fas fa-chevron-down "></i>
          </button>
          <div className="h-100 overflow-auto bg-white">
            <div className="container">
              <h2 className="mt-3 mb-3 mb-lg-5">Quote Summary</h2>
              <EventSummaryTable />
              <PriceSummaryTable />
              {isInsuredInformationPageValid && <InsuredSummaryTable />}
              {isInsuranceContactPageValid && <InsuranceContactSummaryTable />}
            </div>
            <PriceSummaryFooter className="fixed-bottom" />
          </div>
        </StyledQuoteSummaryFloater>
      </Modal>
    </StyledQuoteSummaryFloater>
  );
};

QuoteSummaryFloater.propTypes = propTypes;

export default QuoteSummaryFloater;
