export const SPECIAL_INSURANCE_THEME = {
  themeSlug: null,
  title: "Special Insurance",
  favicons: [
    { rel: "shortcut icon", href: "/favicon.ico" },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/favicons/special-insurance/apple-touch-icon.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicons/special-insurance/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/favicons/special-insurance/favicon-16x16.png",
    },
    {
      rel: "mask-icon",
      href: "/favicons/special-insurance/safari-pinned-tab.svg",
      color: "#0070a7",
    },
    { rel: "shortcut icon", href: "/favicons/special-insurance/favicon.ico" },
  ],
  breakpoints: {
    xxs: "0",
    xs: "375px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
  },
  palette: {
    primary: {
      main: "#8f62ff", // required
      contrastText: "#fff",
      dark: "#5816ff",
    },
    secondary: {
      main: "#333",
      dark: "#333",
      contrastText: "#fff",
    },
    error: {
      light: "#ecf0f7",
      main: "#0d3a68",
    },
    danger: {
      main: "#cf3a2f",
      light: "#faebea",
    },
    success: {
      main: "#86cf59",
      dark: "#5aa02e",
      contrastText: "#fff",
    },
  },
  typography: {
    fonts: {
      primary: "Object sans, sans-serif", // required
    },
    h1: ``,
    h2: `
      color: #000;
    `,
    h3: `
      color: #000;
    `,
    h4: ``,
    h5: ``,
    h6: ``,
  },
  globalStyles: `
    @font-face {
      font-family: 'Object Sans';
      font-style: normal;
      font-weight: 400;
      src: local('Object Sans'), url('/fonts/ObjectSansRegular.woff') format('woff');
    }

    @font-face {
        font-family: 'Object Sans';
        font-style: normal;
        font-weight: 800;
        src: local('Object Sans'), url('/fonts/ObjectSansHeavy.woff') format('woff');
    }

    body {
      color: #333;
    }

    .themed-header {
      z-index: 1040;
    }

    .header__side-modal {
      z-index: 1030;
    }

    .header__side-modal-backdrop {
      z-index: 1020;
    }

    .coverage-summary, .quote-summary-floater__dialog {
      .summary-table {
        &__heading {
          border-radius: 16px;
          padding: 16px 24px;
          margin-left: -24px;
          margin-right: -24px;
        }

        &__item-label {
          font-weight: 600;
        }

        &--event-details {
          .summary-table__heading {
            background: #86cf59;
          }
        }

        &--venue-details {
          .summary-table__heading {
            background: #fdaf13;
          }
        }

        &--insured {
          .summary-table__heading {
            background: #333;
            color: #fff;
          }
        }

        &--insurance-contact {
          .summary-table__heading {
            background: #fdaf13;
          }
        }
      }
    }

    .page-version-2.page-index,
    .page-event-selection,
    .page-underwriting-questions,
    .page-event-information,
    .page-limits,
    .page-insured-information,
    .page-insurance-contact,
    .page-additional-insured,
    .page-payment {
      footer {
        margin-top: -48px;
        border-radius: 0;
      }
    }

    // Datepicker styles needed here for overlay

    .rdp {
      &-day {

        &_selected:not(.rdp-day_disabled) {
          background-color: transparent !important;
          .rdp-day-content {
            background-color: #333 !important;
          }
        }

        &:hover:not(.rdp-day_selected, .rdp-day_disabled),
        &_in-hover-range:not(.rdp-day_selected, .rdp-day_disabled) {
          .rdp-day-content {
            color: #333 !important;
            border: 2px solid #333 !important;
            background-color: #fff !important;
            outline-color: #333 !important;
          }
        }
      }


      &-button {
        &:focus-visible,
        &:focus {
          background-color: #fff !important;
          border-color: #333 !important;
          outline-color: #333 !important;
        }
      }
    }
  `,
  components: {
    Alert: {
      customCss: `border-radius: 16px;`,
    },
    Button: {
      customCss: `
        font-family: Object sans, sans-serif;
        box-shadow: none;
        outline: 0;
        transition: background-color .4s ease;
        position: relative;
        line-height: 1.5;
        padding: 8px 16px;
        border-radius: 35px;
        font-size: 16px;
        font-family: Object-Sans,sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: .02em;

        &:not(:hover):not(.event-card__select-btn):not(:focus:not(:disabled)), &:disabled:hover {
          color: #333;
        }

        &:disabled {
          opacity: 0.4;
        }
      `,
    },
    ButtonToggle: {
      backgroundColor: "#fff",
      backgroundColorActive: "#333",
      borderColor: "#333",
      borderColorActive: "#333",
      color: "#333",
      colorActive: "#fff",
      borderRadius: "35px",
    },
    CheckboxField: {
      activeColor: "#333",
      activeSecondaryColor: "#e0e0e0",
      customCss: `
        .custom-control-label::before {
          width: 1.5rem;
          height: 1.5rem;
        }

        .form-error__label.custom-control-label::before {
          border-color: #cf3a2f;
        }

        .custom-control-label::after {
          width: 1.5rem;
          height: 1.5rem;
        }
      `,
    },
    DateRangePickerField: {
      dateSelectedColor: "#333",
    },
    EventCard: {
      outlineColor: "#333",
      outlineColorActive: "#8f62ff",
      borderRadius: "16px",
      backgroundColor: "#fff",
      backgroundColorActive: "#8f62ff",
      dividerColor: "transparent",
      color: "#333",
    },
    Footer: {
      navItems: [
        {
          label: `T. +800-310-3351 option 2`,
          url: `tel:8003103351`,
          target: "_self",
        },
        {
          label: "Legal & Privacy",
          url: "https://specialinsurance.com/specialinsurance/legal-privacy/",
        },
        {
          label: "Terms & Conditions",
          url: "https://specialinsurance.com/specialinsurance/terms-conditions/",
        },
        {
          label: "Sample Policy and Certificates",
          url: "https://specialinsurance.com/sample-policy-and-certificates/",
        },
        {
          label: "Refunds & Cancellations",
          url: "https://specialinsurance.com/specialinsurance/refunds-cancellations/",
        },
        {
          label: "Blog",
          url: "https://specialinsurance.com/blog/",
        },
        {
          label: "Site Map",
          url: "https://specialinsurance.com/specialinsurance/site-map/",
        },
      ],
      customCss: `
        background: #000;
        border-radius: 24px 24px 0 0;

        .themed-footer__copyright {
          color: #fff;
        }

        .themed-footer__legal {
          color: #fff;
        }
      `,
    },
    FooterLink: {
      customCss: `
        transition: all .4s ease;
        color: #fff;
        &:hover {
          color: #86cf59;
        }
      `,
    },
    Header: {
      logoUrl: "/logos/special-insurance-logo.png",
      navItems: [
        {
          label: "For Agents",
          url: "https://specialinsurance.com/specialinsurance/for-agents/",
        },
        {
          label: "For Venues",
          url: "https://specialinsurance.com/specialinsurance/for-venues/",
        },
        {
          label: "FAQ",
          url: "https://specialinsurance.com/specialinsurance/faq/",
        },
        {
          label: "About",
          url: "https://specialinsurance.com/specialinsurance/about-us/",
        },
        {
          label: "Contact",
          url: "https://specialinsurance.com/specialinsurance/contact/",
        },
      ],
      customCss: `
        position: relative;
        i {
          &.fa-bars, &.fa-times {
            color: #fdaf13;
            transition: color .4s ease;

            &:hover {
              color: #8f62ff;
            }
          }
        }
      `,
    },
    HeaderLogo: {
      href: "https://specialinsurance.com/",
      customCss: `
        &.defaultLogo {
          width: 110px;
        }
        &.customLogo {
          max-width: 500px;
          max-height: 100px;
          @media only screen and (max-width: 600px) {
          max-width: 50vw;
        }
      }
      `,
    },
    HeaderLink: {
      customCss: `
        transition: all .4s ease;
        &:hover {
          color: #86cf59;
        }
      `,
    },
    Input: {
      customCss: `
        border-color: #333;
        border-radius: 8px;
        color: #333;

        &:focus {
          border-color: #333;
          box-shadow: 0 0 0 1px #333;
        }
      `,
    },
    QuoteSummaryFloater: {
      customCss: `
        .quote-summary-floater__button,
        .quote-summary-floater__btn-copy {
          background-color: #fdaf13;
          color: #333;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
        }

        .quote-summary-floater__total {
          background-color: transparent;
          color: #333;
        }

        .quote-summary-floater__arrow {
          color: #333;
        }

        .quote-summary-floater__arrow-close {
          color: #86cf59;
        }
      `,
    },
    RadioFieldGroup: {
      checkRadius: "20%",
      borderRadius: "20%",
      radioColor: "#333",
      radioColorActive: "#333",
      radioColorSecondaryActive: "#e0e0e0",
    },
    Select: {
      borderColor: "#333",
      borderColorActive: "#333",
      indicatorColor: "#333",
      borderRadius: "8px",
      fontFamily: "Object sans, sans-serif",
    },
    TextField: {
      customCss: `
      `,
    },
  },
};
