import { Nav } from "react-bootstrap";
import PropTypes from "prop-types";

const propTypes = {
  hideMobileNav: PropTypes.func.isRequired,
};

const Navigation = ({ hideMobileNav }) => {
  return (
    <>
      <Nav className="navigation__nav-links ml-lg-auto">
        <Nav.Item className="navigation__nav-link-item">
          <a
            href="https://gbli.com/gbli/about-us/"
            className="btn btn-link"
            target="_blank"
            rel="noreferrer"
          >
            About Us
            <i className="fa fa-chevron-right ml-auto d-lg-none" />
          </a>
        </Nav.Item>
        <Nav.Item className="navigation__nav-link-item">
          <a
            href="https://gbli.com/gbli/claims/"
            className="btn btn-link"
            target="_blank"
            rel="noreferrer"
          >
            File a Claim
            <i className="fa fa-chevron-right ml-auto d-lg-none" />
          </a>
        </Nav.Item>
        <Nav.Item className="navigation__nav-link-item">
          <a href="/" className="btn btn-link" target="_blank" rel="noreferrer">
            Refunds & Cancellations
            <i className="fa fa-chevron-right ml-auto d-lg-none" />
          </a>
        </Nav.Item>
      </Nav>
    </>
  );
};

Navigation.propTypes = propTypes;

export default Navigation;
