import {
  VENUE_SELECTION_URL,
  EVENT_INFORMATION_URL,
  LIMITS_URL,
  COVERAGE_SUMMARY_URL,
  PAYMENT_URL,
} from "src/Constants/constants";
import { isVenuePageValid } from "src/Selectors/Venue";
import { isEventSelectionPageValid } from "src/Selectors/Event";
import { isUnderwritingQuestionsPageValid } from "src/Selectors/UnderwritingQuestions";
import { getIsLimitsPageValid } from "src/Selectors/Limits";
import { isEventInformationPageValid } from "src/Selectors/Event";

import VenueCombined from "src/Components/Combined/VenueCombined";
import LimitsCombined from "src/Components/Combined/LimitsCombined";
import QuoteSummaryFloater from "../Components/QuoteSummaryFloater/QuoteSummaryFloater";
import EventSelectionCombined from "src/Components/Combined/EventSelectionCombined";
import EventInformationCombined from "src/Components/Combined/EventInformationCombined";
import InsuredPaymentCombined from "src/Components/Combined/InsuredPaymentCombined";
import CoverageSummaryCombined from "src/Components/Combined/CoverageSummaryCombined";

export const formStepsConfig = {
  // Key order matters here. The guard will check page validity of all previous steps relative to the currentPath.
  [VENUE_SELECTION_URL]: {
    component: () => (
      <>
        <VenueCombined />
        <EventSelectionCombined />
      </>
    ),
    isPageValidSelector: (state) =>
      isVenuePageValid(state) && isEventSelectionPageValid(state),
    children: <QuoteSummaryFloater />,
  },
  [EVENT_INFORMATION_URL]: {
    component: () => (
      <>
        <EventInformationCombined />
      </>
    ),
    isPageValidSelector: (state) =>
      isUnderwritingQuestionsPageValid(state) &&
      isEventInformationPageValid(state),
    children: <QuoteSummaryFloater />,
  },
  [LIMITS_URL]: {
    component: LimitsCombined,
    isPageValidSelector: getIsLimitsPageValid,
    children: <QuoteSummaryFloater />,
  },
  [COVERAGE_SUMMARY_URL]: {
    component: CoverageSummaryCombined,
    isPageValidSelector: null,
  },
  [PAYMENT_URL]: {
    component: InsuredPaymentCombined,
    isPageValidSelector: null,
    children: <QuoteSummaryFloater />,
  },
};
