import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import GBLILogo from "@gbli-events/common/src/Assets/Images/gbli-logo-colour-logo.svg";
import { Navbar, Modal } from "react-bootstrap";
import Navigation from "./Navigation";
import { resetApplicationForm } from "src/Actions/actions";

const GBLIHeader = () => {
  const dispatch = useDispatch();
  const [showMobileNav, setShowMobileNav] = useState(false);

  const hideMobileNav = () => setShowMobileNav(false);

  return (
    <>
      <header className="header">
        <div className="container">
          <Navbar className="py-1" expand="lg">
            <Navbar.Brand>
              <Link to="/" onClick={() => dispatch(resetApplicationForm())}>
                <img src={GBLILogo} alt="Logo" className="header__logo" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              className="border-0 p-0"
              onClick={() => {
                setShowMobileNav(true);
              }}
              aria-controls="responsive-navbar-nav"
            >
              <i className="fas fa-bars c-gold header__bars"></i>
            </Navbar.Toggle>
            <div className="d-none d-lg-block ml-auto">
              <Navigation hideMobileNav={hideMobileNav} />
            </div>
          </Navbar>
        </div>
        <div className="header__banner">
          <div className="container">
            <h1>Special Event Insurance</h1>
          </div>
        </div>
      </header>
      <Modal
        dialogClassName="header__dialog"
        show={showMobileNav}
        onHide={hideMobileNav}
      >
        <button
          type="button"
          className="undecorate-btn d-flex ml-auto p-4 mr-1 font-20"
          onClick={hideMobileNav}
        >
          <i className="fal fa-times" />
        </button>
        <Link
          className="pl--30 mb-2 mt-4"
          to="/"
          onClick={() => {
            dispatch(resetApplicationForm());
            hideMobileNav();
          }}
        >
          HOME
        </Link>
        <Navigation hideMobileNav={hideMobileNav} />
      </Modal>
    </>
  );
};

export default GBLIHeader;
