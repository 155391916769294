import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import createRootReducer from "./Reducers/reducers";
import StateStorage from "./Helpers/StateStorage";
import FormValueFilter from "./Helpers/FormValueFilter";
import { screenResize } from "./Actions/actions";
import ReduxFormMiddleware from "./Middleware/ReduxFormMiddleware";
import CheckoutPageMiddleware from "./Middleware/CheckoutPageMiddleware";
import InitializeEventTypesMiddleware from "./Middleware/InitializeEventTypesMiddleware";
import InitializeStatesMiddleware from "./Middleware/InitializeStatesMiddleware";
import InitializeUnderwritingQuestionsMiddleware from "./Middleware/InitializeQuestionsMiddleware";
import PageClassMiddleware from "./Middleware/PageClassMiddleware";
import EventTypeValidationMiddleWare from "./Middleware/EventTypeValidationMiddleware";
import VenueReferralMiddleware from "./Middleware/VenueReferralMiddleware";
import ThemeMiddleware from "./Middleware/ThemeMiddleware";
import { VENUE_SELECTION_URL } from "./Constants/constants";
import { getFormRelativePath } from "./Helpers/URLParser";
import InitializeAuthUserMiddleware from "./Middleware/InitializeAuthUserMiddleware";

// Start with a fresh form if loading application from home screen
if (getFormRelativePath(window.location.pathname) === VENUE_SELECTION_URL) {
  StateStorage.clearState();
}

const configureStore = function configureStore() {
  const store = createStore(
    createRootReducer(),
    // Load the stored state - preserves state on refreshes
    // Note that FormValueFilter is used to help convert stringified values back
    // to correct object types
    StateStorage.loadState(FormValueFilter),
    composeWithDevTools(
      applyMiddleware(
        InitializeEventTypesMiddleware,
        InitializeStatesMiddleware,
        InitializeUnderwritingQuestionsMiddleware,
        ThemeMiddleware,
        PageClassMiddleware,
        CheckoutPageMiddleware,
        EventTypeValidationMiddleWare,
        ReduxFormMiddleware,
        VenueReferralMiddleware,
        InitializeAuthUserMiddleware,
        thunkMiddleware
      )
    )
  );
  // Stored state subscribes to changes and updates store state
  StateStorage.subscribeToStore(store);
  return store;
};

const store = configureStore();

// mobile/desktop display of Summary container
store.dispatch(screenResize(window.innerWidth));

export default store;
