import React from "react";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import { glRequired } from "@gbli-events/common/src/Helpers/validators";
import CurrencyHelper from "src/Helpers/CurrencyHelper";
import SelectList from "@gbli-events/common/src/Components/FormElements/SelectList";
import PropTypes from "prop-types";
import initialFormValues from "src/Constants/initialFormValues";
import { getTheme } from "src/Selectors/Shared";

const propTypes = {
  limits: PropTypes.arrayOf(
    PropTypes.shape({
      occurrence: PropTypes.number.isRequired,
      aggregate: PropTypes.number.isRequired,
    })
  ).isRequired,
};

const GeneralLiabilityField = ({ limits }) => {
  const options = limits.map((limit) => ({
    value: limit,
    label: `${new CurrencyHelper(
      limit.occurrence
    ).dollars()}/${new CurrencyHelper(limit.aggregate).dollars()}`,
  }));

  const theme = useSelector(getTheme);

  return (
    <Field
      name="generalLiabilityCoverage"
      component={SelectList}
      theme={theme}
      options={options}
      placeholder="Select Coverage"
      validate={glRequired}
      resetValue={initialFormValues().generalLiabilityCoverage}
      components={{
        ClearIndicator: () => null,
      }}
    />
  );
};

GeneralLiabilityField.propTypes = propTypes;

export default GeneralLiabilityField;
