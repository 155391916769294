import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import Alert from "@gbli-events/common/src/Components/Themed/Alert";
import { venueAddressRequired } from "@gbli-events/common/src/Helpers/validators";
import { PlacesSearch } from "@jauntin/react-ui";
import { GBLI_PHONE_EVENTS_TITLE } from "@gbli-events/common/src/Constants/shared";
import { getUsStates } from "src/Selectors/Shared";
import { createStateOptions } from "../../Helpers/StateSelectOptions";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getThemeColor } from "@gbli-events/common/src/Helpers/themes";

const PlacesSearchStyles = styled.div`
  input:focus {
    border-color: ${({ theme }) => getThemeColor(theme)};
    box-shadow: 0 0 0 1px ${({ theme }) => getThemeColor(theme)};
  }

  input {
    ${({ theme }) => theme.components.Input?.customCss}
  }

  .venueInput {
    ${({ theme }) => theme.components.InputGroup?.customCss}
  }

  .venueInput .form-error {
    text-align: center;
  }

  .venueInput .form-error + .venueInput__field .fa-search + input {
    border-color: ${({ theme }) => theme.palette.danger.main};
  }

  .venueInput .label {
    display: block;
  }

  .venueInput.form-group .label.form-error__label {
    margin-bottom: 0;
  }

  .venueInput .venueInput__field {
    position: relative;
  }
`;

const SearchByAddress = ({
  label,
  onSelect,
  address,
  venueState,
  mobile,
  isValidAddress,
  clearForm,
  hasInput,
  isValidState,
  name,
  setIsKentuckyCity,
  setHasLookedUpTax,
  venueSearchReadOnly,
  setVenueSearchReadOnly,
  isBlocked,
  genericServerError,
  venueName,
  alreadySelectedError,
}) => {
  let errorMessage = "";
  const usStatesOptions = createStateOptions(useSelector(getUsStates));

  if (venueState) {
    errorMessage = isValidState
      ? "A specific venue address is required"
      : `Coverage is currently not available for venues in ${
          usStatesOptions.find((state) => state.value === venueState)?.label
        }`;
  } else {
    errorMessage =
      "Not enough venue information available. Please search again or enter a venue code if available.";
  }

  return (
    <>
      <PlacesSearchStyles>
        <Field
          label={label}
          component={PlacesSearch}
          placeholder={
            mobile ? "Venue name or address" : "Search by venue name or address"
          }
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          name={name}
          validate={[venueAddressRequired]}
          hasInput={hasInput}
          readOnly={venueSearchReadOnly}
          showSearchIcon={!hasInput}
          onSelect={(
            placeId,
            address,
            addressComponents,
            utcOffset,
            placeName
          ) => {
            onSelect(
              placeName,
              placeId,
              address,
              addressComponents,
              utcOffset,
              setIsKentuckyCity,
              setHasLookedUpTax,
              setVenueSearchReadOnly
            );
          }}
          clearForm={() => clearForm(setVenueSearchReadOnly)}
          onKeyDown={({ key }) => {
            if (
              (key === "Backspace" || key === "Delete") &&
              hasInput &&
              !!venueState
            ) {
              clearForm(setVenueSearchReadOnly);
            }
          }}
          className="form-group"
        />
      </PlacesSearchStyles>

      {isBlocked && (
        <Alert variant="info" className="mt-3">
          <i className="far fa-exclamation-circle alert__icon" />
          <div className="alert__text">
            Unfortunately, we cannot provide a policy from our online special
            event insurance program for the location provided. For more
            information, please call{" "}
            <span className="text-nowrap">{GBLI_PHONE_EVENTS_TITLE}</span>.
          </div>
        </Alert>
      )}

      {alreadySelectedError && (
        <>
          <div className="font-weight-bold text-danger">
            You have already chosen
          </div>
          <div>
            <i className="fal fa-times-circle text-danger" />
            &nbsp;
            <span className="font-italic text-danger">{address}</span>
          </div>
        </>
      )}

      {genericServerError && (
        <Alert variant="error" className="mt-3">
          <i className="far fa-exclamation-circle alert__icon" />
          <div className="alert__text">
            Something went wrong. Please try again.
          </div>
        </Alert>
      )}

      {address && !isBlocked && !alreadySelectedError && (
        <>
          {isValidState && isValidAddress ? (
            <>
              <div className="font-weight-bold address-chosen">
                Address Chosen
              </div>
              <div className="mb-4">
                <span className="font-italic address-chosen">
                  {venueName && `${venueName}, `}
                  {address}
                </span>
              </div>
            </>
          ) : (
            <Alert variant="error" className="mt-3">
              <div className="alert__text">
                <div className="font-weight-bold">{errorMessage}</div>
                <div>
                  <i className="fal fa-times-circle" />
                  &nbsp;
                  <span className="font-italic">{address}</span>
                </div>
              </div>
            </Alert>
          )}
        </>
      )}
    </>
  );
};

SearchByAddress.propTypes = {
  onSelect: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  venueState: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
  isValidState: PropTypes.bool.isRequired,
  isValidAddress: PropTypes.bool.isRequired,
  clearForm: PropTypes.func.isRequired,
  hasInput: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  setIsKentuckyCity: PropTypes.func.isRequired,
  setHasLookedUpTax: PropTypes.func.isRequired,
  venueSearchReadOnly: PropTypes.bool.isRequired,
  setVenueSearchReadOnly: PropTypes.func.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  genericServerError: PropTypes.bool.isRequired,
  venueName: PropTypes.string.isRequired,
  alreadySelectedError: PropTypes.bool,
};

export default SearchByAddress;
