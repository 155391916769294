import { getMediaBreakpointUp } from "@gbli-events/common/src/Helpers/themes";
import styled from "styled-components";

const StyledEventCard = styled.button`
  &.event-card {
    padding: 0;
    background: ${({ theme }) => theme.components.EventCard?.backgroundColor};
    border: none;
    border-radius: ${({ theme }) => theme.components.EventCard?.borderRadius};
    width: 100%;
    margin: 10px 0px;
    color: ${({ theme }) => theme.components.EventCard?.color};
    box-shadow: 0 0 0 1px
      ${({ theme }) => theme.components.EventCard?.outlineColor} !important;
    overflow: hidden;
    outline: none;

    p {
      color: ${({ theme }) => theme.components.EventCard?.color};
    }

    &--selected,
    &:focus,
    &:active {
      outline: none;
      background: ${({ theme }) =>
        theme.components.EventCard?.backgroundColorActive};
      box-shadow: 0 0 0 3px
        ${({ theme }) => theme.components.EventCard?.outlineColorActive} !important;
    }

    ${({ theme }) => getMediaBreakpointUp(theme.breakpoints.lg)} {
      display: flex;
      flex-direction: column;
      height: 350px;
      width: 220px;
    }

    ${({ theme }) => getMediaBreakpointUp(theme.breakpoints.xl)} {
      height: 404px;
      width: 257px;
    }

    .event-card__image {
      display: none;

      ${({ theme }) => getMediaBreakpointUp(theme.breakpoints.lg)} {
        display: block;
        width: 100%;
        height: 136px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    .event-card__copy {
      padding: 1rem 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      ${({ theme }) => getMediaBreakpointUp(theme.breakpoints.lg)} {
        flex: 1;
        width: 100%;
      }
    }

    .event-card__title {
      font-weight: bold;
    }

    .event-card__divider {
      height: 2px;
      width: 100px;
      background-color: ${({ theme }) =>
        theme.components.EventCard?.dividerColor};
      margin: 1rem 0;

      ${({ theme }) => getMediaBreakpointUp(theme.breakpoints.lg)} {
        position: absolute;
        top: 210px;
      }

      ${({ theme }) => getMediaBreakpointUp(theme.breakpoints.xl)} {
        top: 223px;
      }
    }

    .event-card__sub-title {
      ${({ theme }) => getMediaBreakpointUp(theme.breakpoints.lg)} {
        margin-top: auto;
        margin-bottom: 12px;
      }
    }

    .event-card__select-btn {
      display: none;

      ${({ theme }) => getMediaBreakpointUp(theme.breakpoints.lg)} {
        display: block;
        width: 140px;
      }
    }

    ${({ theme }) => theme.components.EventCard?.customCss}
  }
`;

export default StyledEventCard;
