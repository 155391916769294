import styled from "styled-components";
import { getMediaBreakpointUp } from "@gbli-events/common/src/Helpers/themes";

const HeaderStyles = ({ theme }) => `
  .themed-header__navigation {
    flex-direction: column;
    .themed-header__navigation-item {
      margin-left: 0;
      padding: 30px 0;
    }
    ${getMediaBreakpointUp(theme.breakpoints.lg)} {
      flex-direction: row;
      .themed-header__navigation-item {
        margin-left: 30px;
      }
    }
  }

  ${theme.components.Header?.customCss}
`;

const HeaderDivStyles = ({ theme }) => `
  ${HeaderStyles({ theme })}
  .themed-header__navigation {
    padding-top: 120px;
  }
`;

export const StyledHeader = styled.header(HeaderStyles);

export const StyledHeaderDiv = styled.div(HeaderDivStyles);
