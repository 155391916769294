import React, { useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {
  VENUE_SELECTION_URL,
  THANK_YOU_URL,
  QUOTE_URL,
} from "src/Constants/constants";
import GuardedRoute from "@gbli-events/common/src/Components/GuardedRoute";
import { canActivateFormStep } from "src/Guards/FormStepGuard";
import CustomSwitch from "@gbli-events/common/src/Components/CustomSwitch";
import QuoteLoader from "../QuoteLoader/QuoteLoader";
import ThankYouContainer from "../../Containers/ThankYouContainer";
import {
  getFormChildPath,
  getFormChildPathDefinition,
} from "src/Helpers/URLParser";
import {
  getFilteredFormStepsConfig,
  getIsEmbed,
  getThemeSlug,
} from "src/Selectors/Shared";

const libraries = ["places"];

const Form = ({
  setAndSearchVenueCode,
  setAndSearchProducerCode,
  setAndSearchFacilityCode,
  checkBoilerplateReferral,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    language: "en",
  });
  useEffect(() => {
    const { search } = window.location;

    if (search && isLoaded) {
      setAndSearchVenueCode(search);
      setAndSearchProducerCode(search);
      setAndSearchFacilityCode(search);
      checkBoilerplateReferral(search);
    }
  }, [
    setAndSearchVenueCode,
    setAndSearchProducerCode,
    setAndSearchFacilityCode,
    checkBoilerplateReferral,
    isLoaded,
  ]);

  const formStepsConfig = useSelector(getFilteredFormStepsConfig);
  const isEmbed = useSelector(getIsEmbed);
  const themeSlug = useSelector(getThemeSlug);
  const GuardRedirectComponent = () => (
    <Redirect to={getFormChildPath(VENUE_SELECTION_URL, isEmbed, themeSlug)} />
  );

  const FormSteps = Object.entries(formStepsConfig).map(
    ([key, { component: Component, children }]) => {
      if (key === VENUE_SELECTION_URL) {
        return (
          <Route
            key={key}
            exact
            path={getFormChildPathDefinition(key, isEmbed, themeSlug)}
            render={(props) => (
              <>
                <Component {...props} />
                {children}
              </>
            )}
          />
        );
      }

      return (
        <GuardedRoute
          guards={[canActivateFormStep(key)]}
          key={key}
          path={getFormChildPathDefinition(key, isEmbed, themeSlug)}
          component={Component}
          redirectComponent={GuardRedirectComponent}
        >
          {children}
        </GuardedRoute>
      );
    }
  );

  return (
    isLoaded && (
      <form>
        <CustomSwitch
          notFoundRedirectUrl={getFormChildPathDefinition(
            VENUE_SELECTION_URL,
            isEmbed,
            themeSlug
          )}
        >
          {FormSteps}
          <Route
            path={`${getFormChildPathDefinition(
              QUOTE_URL,
              isEmbed,
              themeSlug
            )}/:id`}
            component={QuoteLoader}
          />
          <Route
            path={getFormChildPathDefinition(THANK_YOU_URL, isEmbed, themeSlug)}
            component={ThankYouContainer}
          />
        </CustomSwitch>
      </form>
    )
  );
};

Form.propTypes = {
  setAndSearchVenueCode: PropTypes.func.isRequired,
  setAndSearchProducerCode: PropTypes.func.isRequired,
  setAndSearchFacilityCode: PropTypes.func.isRequired,
  checkBoilerplateReferral: PropTypes.func.isRequired,
};

export default Form;
