import React from "react";
import TotalQuote from "../TotalQuote";
import PropTypes from "prop-types";
import StyledPriceSummaryFooter from "./StyledPriceSummaryFooter";

const propTypes = {
  className: PropTypes.string,
  showLegal: PropTypes.bool,
};
const defaultProps = {
  showLegal: false,
};

const PriceSummaryFooter = ({ className, showLegal }) => (
  <StyledPriceSummaryFooter
    className={`price-summary-footer ${className ? className : ""}`}
  >
    <div className="container">
      <div className="row">
        <div className="col price-summary-footer__premium-total summary-table__item summary-table__item--md-75-25 mb-0 flex-row">
          <div className="summary-table__item-label">Premium Total:</div>
          <div className="summary-table__item-value ml--20 ml-md-0">
            <TotalQuote />
          </div>
        </div>
      </div>
      {showLegal && (
        <div className="row">
          <div className="col pb-4 price-summary-footer__legal">
            <div className="price-summary-footer__legal-heading mb-2">
              Fraud Warning
            </div>
            <div className="price-summary-footer__legal-text">
              <div>
                Please refer to{" "}
                <a
                  className="price-summary-footer__legal-link"
                  href="/pdfs/ACORD.63.Fraud.Statements.pdf"
                  target="_blank"
                >
                  Acord 63
                </a>{" "}
                for state specific fraud warnings.
              </div>
              <div>
                (All other States): Any person who knowingly presents a false or
                fraudulent claim for payment of a loss or benefit or knowingly
                presents false information in an application for insurance is
                guilty of a crime and may be subject to fines and confinement in
                prison.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </StyledPriceSummaryFooter>
);

PriceSummaryFooter.propTypes = propTypes;
PriceSummaryFooter.defaultProps = defaultProps;

export default PriceSummaryFooter;
