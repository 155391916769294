import { formStepsConfig as formStepsV1 } from "src/Helpers/FormStepsConfig";
import { formStepsConfig as formStepsV2 } from "src/Helpers/FormStepsConfigCombined";
import { ADDITIONAL_INSURED_URL } from "src/Constants/constants";
import { getSelectStyles } from "@gbli-events/common/src/Helpers/ReactSelectStyle";
export const getUsStates = (state) => state.formPage.usStates;

export const getFilteredFormStepsConfig = (state) => {
  const hasCertHolders = !!state.formPage.summary.additionalCertHolders.length;
  const formStepsConfig =
    process.env.REACT_APP_UI_VERSION === "2" ? formStepsV2 : formStepsV1;

  const result = {
    ...formStepsConfig,
  };

  if (!hasCertHolders) delete result[ADDITIONAL_INSURED_URL];

  return result;
};

export const getIsEmbed = (state) => state.formPage.isEmbed;

export const getIsAppDataLoaded = (state) => {
  const isStatesLoaded = !!state.formPage.usStates.length;
  const isThemeLoaded = !!state.formPage.theme;

  return isStatesLoaded && isThemeLoaded;
};

export const getHasAppDataError = (state) => {
  return state.formPage.appDataError;
};

export const getReferralQueryParams = (state) => {
  const { facilityCode, venueCode, producerCode } = state.formPage.referral;

  const queryParams = {
    prop: facilityCode && venueCode ? `${facilityCode}-${venueCode}` : null,
    pro: producerCode,
  };

  const filteredKeys = Object.keys(queryParams).filter(
    (key) => queryParams[key]
  );

  if (!filteredKeys.length) return "";

  const filteredQueryParams = filteredKeys.reduce((params, key) => {
    params[key] = queryParams[key];
    return params;
  }, {});

  const result = `?${new URLSearchParams(filteredQueryParams).toString()}`;

  return result;
};

export const getValidatingReferralCodes = (state) => {
  const { fetchingVenuePresets, fetchingProducerCode } =
    state.formPage.referral;

  return fetchingVenuePresets || fetchingProducerCode;
};

export const getTheme = (state) => state.formPage.theme;

export const getThemeSlug = (state) => getTheme(state).themeSlug;

export const getThemedSelectStyles = (state) =>
  getSelectStyles(getTheme(state));

export const getStatesByInsuranceCompany =
  (company, availableStates, withAnd = false) =>
  (state) => {
    const companies = state.formPage.insuranceCompanies.filter(
      (item) => availableStates.includes(item.state) && item.company === company
    );
    const states = companies.map((company) => company.state);
    if (withAnd) {
      states[states.length - 1] = `and ${states[states.length - 1]}`;
    }
    return states;
  };

export const getShowAgentsLink = (state) =>
  state.formPage.referral.producerCode === null;

export const getReferralLogo = (state) => {
  if (state.formPage.venue.facilityLogoUrl) {
    return {
      url: state.formPage.venue.facilityLogoUrl,
      alt: "Organization Logo",
    };
  }

  if (state.formPage.referral.facilityLogoUrl) {
    return {
      url: state.formPage.referral.facilityLogoUrl,
      alt: "Organization Logo",
    };
  }

  if (state.formPage.referral.producerLogoUrl) {
    return {
      url: state.formPage.referral.producerLogoUrl,
      alt: "Producer Logo",
    };
  }

  return null;
};
