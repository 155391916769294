import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FieldArray } from "redux-form";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import SearchByAddress from "./SearchByAddress";
import venuePropType from "../../Constants/venueTypes";
import manualAddressPropType from "../../Constants/manualAddressTypes";
// import WeddingImage from '@gbli-events/common/src/Assets/Images/wedding-md.jpg';
// import DonationImage from '@gbli-events/common/src/Assets/Images/donation-md.jpg';
// import BirthdayCandlesImage from '@gbli-events/common/src/Assets/Images/birthday-candles-md.jpg';
// import BabyShowerImage from '@gbli-events/common/src/Assets/Images/baby-shower-md.jpg';
import ThemeColor from "@gbli-events/common/src/Components/Themed/ThemeColor";
import ThemeBackgrounColor from "@gbli-events/common/src/Components/Themed/ThemeBackgroundColor";
import OptionalVenues from "./OptionalVenues";
import NavContinueButton from "../NavContinueButton";
// import { getIsEmbed } from 'src/Selectors/Shared';
import {
  getBlockedStateCodes,
  getIsKnownVenue,
  getVenueStatesMatch,
} from "src/Selectors/Venue";
import { GBLI_PHONE_EVENTS_TITLE } from "@gbli-events/common/src/Constants/shared";
import Alert from "@gbli-events/common/src/Components/Themed/Alert";
import SpecialTaxFields from "./SpecialTaxFields";

const Venue = ({
  addressComponents,
  confirm,
  onSelect,
  placeId,
  address,
  venueState,
  mobile,
  clearForm,
  clearVenueReferral,
  hasInput,
  hasRegionalTax,
  taxRegions,
  isKentuckyEntity,
  fetchingVenuePresets,
  venueName,
  manualVenueAddress,
  lookupTax,
  isBlocked,
  genericServerError,
  updateManualCity,
  isVenuePageValid,
  setKentuckyCity,
  setIsKentuckyCity,
  setHasLookedUpTax,
  isValidVenueAddress,
  isValidVenueState,
}) => {
  // const isEmbed = useSelector(getIsEmbed);
  const isKnownVenue = useSelector(getIsKnownVenue);
  const venueStatesMatch = useSelector(getVenueStatesMatch);
  const blockedStates = useSelector(getBlockedStateCodes);
  const fetchingTaxInfo = useSelector(
    (state) => state.formPage.fetchingTaxInfo
  );
  /* eslint-disable no-unused-vars */
  const [venueSearchReadOnly, setVenueSearchReadOnly] = useState(false);
  // Invokes tax lookup on mount
  // to allow revalidation on page reload
  const initialTaxLookup = () => {
    lookupTax(
      manualVenueAddress.state,
      manualVenueAddress.city,
      setIsKentuckyCity,
      setHasLookedUpTax,
      false
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initialTaxLookup, []);

  const getKnownVenueAddressLabel = () => {
    return [
      addressComponents.companyName,
      addressComponents.address1,
      addressComponents.city,
      addressComponents.state,
      addressComponents.zip,
      addressComponents.country,
    ]
      .filter((component) => !!component)
      .join(", ");
  };

  return (
    <>
      <div className="venue">
        {/* {!isEmbed && (
          <div className="venue__images">
            <div
              className="venue__image"
              style={{ backgroundImage: 'url(' + WeddingImage + ')' }}
            />
            <div
              className="venue__image"
              style={{ backgroundImage: 'url(' + BirthdayCandlesImage + ')' }}
            />
            <div
              className="venue__image"
              style={{ backgroundImage: 'url(' + DonationImage + ')' }}
            />
            <div
              className="venue__image"
              style={{ backgroundImage: 'url(' + BabyShowerImage + ')' }}
            />
          </div>
        )} */}
        <div className="container">
          {fetchingVenuePresets ? (
            <ThemeColor variant="primary">
              <Spinner
                className="venue__spinner"
                animation="border"
                role="status"
              />
            </ThemeColor>
          ) : (
            <>
              <div className="d-flex align-items-center flex-column venue__sub-heading-wrapper venue__sub-heading-wrapper--with-divider">
                <ThemeBackgrounColor
                  variant="secondary"
                  className="venue__divider"
                />
                <div className="venue__sub-heading">
                  {isKnownVenue ? (
                    <ThemeColor variant="secondary">
                      Get coverage for your event.
                    </ThemeColor>
                  ) : (
                    <ThemeColor variant="secondary">
                      Where do you wish to host your event?
                    </ThemeColor>
                  )}
                </div>
                <div className="venue__restrictions">
                  Coverage is not available for venues in the following states:
                  <br />
                  {blockedStates.join(", ")}
                </div>
              </div>
              <div className="venue__form">
                {isKnownVenue ? (
                  <>
                    <h2 className="venue__heading mt-4 mt-lg-5">
                      Confirm Event Location.
                    </h2>
                    <p className="text-center label-md-20-px">
                      {getKnownVenueAddressLabel()}
                    </p>
                    <p className="text-center">
                      <button
                        className="label-md-20-px p-0 undecorate-btn"
                        type="button"
                        onClick={() => {
                          clearForm(setVenueSearchReadOnly);
                          clearVenueReferral();
                        }}
                      >
                        <ThemeColor
                          variant="secondary"
                          className="font-weight-bold"
                        >
                          ( Location not correct? Click here )
                        </ThemeColor>
                      </button>
                    </p>
                    {isBlocked && (
                      <Alert variant="error" className="mt-3">
                        <i className="far fa-exclamation-circle alert__icon" />
                        <div className="alert__text">
                          Unfortunately, we cannot provide a policy from our
                          online special event insurance program for the
                          location provided. For more information, please call{" "}
                          <span className="text-nowrap">
                            {GBLI_PHONE_EVENTS_TITLE}
                          </span>
                          .
                        </div>
                      </Alert>
                    )}
                  </>
                ) : (
                  <>
                    <h2 className="venue__heading mt-4 mt-lg--40">
                      Choose the location of your event.
                    </h2>
                    <SearchByAddress
                      name="venue"
                      onSelect={onSelect}
                      address={address}
                      venueState={venueState}
                      mobile={mobile}
                      isValidAddress={isValidVenueAddress}
                      clearForm={clearForm}
                      hasInput={hasInput}
                      isValidState={isValidVenueState}
                      hasRegionalTax={hasRegionalTax}
                      taxRegions={taxRegions}
                      isKentuckyEntity={isKentuckyEntity}
                      setKentuckyCity={setKentuckyCity}
                      setIsKentuckyCity={setIsKentuckyCity}
                      setHasLookedUpTax={setHasLookedUpTax}
                      venueSearchReadOnly={!!placeId}
                      setVenueSearchReadOnly={() => {}}
                      isBlocked={isBlocked}
                      genericServerError={genericServerError}
                      updateManualCity={updateManualCity}
                      venueName={venueName}
                    />
                  </>
                )}
                {!isBlocked && (
                  <SpecialTaxFields
                    hasRegionalTax={hasRegionalTax}
                    taxRegions={taxRegions}
                    isKentuckyEntity={isKentuckyEntity}
                    setKentuckyCity={setKentuckyCity}
                  />
                )}
                <FieldArray name="optionalVenues" component={OptionalVenues} />
                {!venueStatesMatch && (
                  <Alert variant="error" className="mt-3">
                    <i className="far fa-exclamation-circle alert__icon" />
                    <div className="alert__text">
                      The state of the second venue must be in the same state as
                      the above primary venue. If your second venue is in a
                      separate state, please purchase a separate policy for that
                      venue.
                    </div>
                  </Alert>
                )}
                <NavContinueButton
                  disabled={!isVenuePageValid || fetchingTaxInfo}
                  onClick={confirm}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
Venue.propTypes = {
  addressComponents: PropTypes.any.isRequired,
  confirm: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeId: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  venueState: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
  clearForm: PropTypes.func.isRequired,
  clearVenueReferral: PropTypes.func.isRequired,
  hasInput: PropTypes.bool.isRequired,
  hasRegionalTax: PropTypes.bool.isRequired,
  taxRegions: PropTypes.arrayOf(venuePropType).isRequired,
  isKentuckyEntity: PropTypes.bool.isRequired,
  fetchingVenuePresets: PropTypes.bool.isRequired,
  venueName: PropTypes.string.isRequired,
  manualVenueAddress: manualAddressPropType.isRequired,
  lookupTax: PropTypes.func.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  genericServerError: PropTypes.bool.isRequired,
  updateManualCity: PropTypes.func.isRequired,
  isVenuePageValid: PropTypes.bool.isRequired,
  setKentuckyCity: PropTypes.func.isRequired,
  setIsKentuckyCity: PropTypes.func.isRequired,
  setHasLookedUpTax: PropTypes.func.isRequired,
  isValidVenueAddress: PropTypes.bool.isRequired,
  isValidVenueState: PropTypes.bool.isRequired,
};

export default Venue;
