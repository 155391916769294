import React, { useEffect } from "react";
import FormStepNavigation from "src/Components/FormStepNavigation";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FieldArray } from "redux-form";
import { initializeForm } from "src/Actions/underwritingQuestions";
import {
  getUnderwritingQuestionFields,
  isUnderwritingQuestionsPageValid,
} from "src/Selectors/UnderwritingQuestions";
import { underwritingQuestionPropType } from "src/Models/UnderwritingQuestionModel";
import UnderwritingQuestionFields from "./UnderwritingQeustionFields";
import NavContinueButton from "../NavContinueButton";

const mapStateToProps = (state) => ({
  underwritingQuestions: getUnderwritingQuestionFields(state),
  isUnderwritingQuestionsPageValid: isUnderwritingQuestionsPageValid(state),
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => dispatch(initializeForm()),
});

const propTypes = {
  underwritingQuestions: PropTypes.arrayOf(underwritingQuestionPropType)
    .isRequired,
  isUnderwritingQuestionsPageValid: PropTypes.bool.isRequired,
  onMount: PropTypes.func.isRequired,
};

const UnderwritingQuestions = ({
  underwritingQuestions,
  isUnderwritingQuestionsPageValid,
  onMount,
}) => {
  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className="underwriting-questions container mb-4">
      <FormStepNavigation />
      <div className="page-heading">
        <h2>Let’s make sure we are a fit for your coverage needs.</h2>
      </div>

      <div className="underwriting-questions__form-container">
        <FieldArray
          name="underwritingQuestions"
          component={UnderwritingQuestionFields}
          underwritingQuestions={underwritingQuestions}
        />
      </div>
      <NavContinueButton disabled={!isUnderwritingQuestionsPageValid} />
    </div>
  );
};

UnderwritingQuestions.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnderwritingQuestions);
