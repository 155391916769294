import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getThemeSlug } from "src/Selectors/Shared";
import { getIsEmbed } from "src/Selectors/Shared";
import { getFormChildPath } from "src/Helpers/URLParser";

export const useGoToFormPath = () => {
  const history = useHistory();
  const themeSlug = useSelector(getThemeSlug);
  const isEmbed = useSelector(getIsEmbed);

  return (path) => {
    history.push(getFormChildPath(path, isEmbed, themeSlug));
  };
};

export const useGetFormPath = (path) => {
  const themeSlug = useSelector(getThemeSlug);
  const isEmbed = useSelector(getIsEmbed);

  return getFormChildPath(path, isEmbed, themeSlug);
};
